// import dummyTransactions from './dummyData'
import * as _ from 'lodash';

import { AuthorizedPost, AuthorizedPut } from '@iso/lib/helpers/resApiRequestorAuth';
import { encryptedGet, encryptedPost, encryptedPut } from '../../library/helpers/resApiEncryptedRequestor';
// import mqttActions from '../mqtt/actions';
import { DELETE, PUT, get, post } from '@iso/lib/helpers/resApiRequestor';
import { all, fork, put, select, takeEvery } from 'redux-saga/effects';

import { pushDataToApp } from '@iso/lib/helpers/mobileAppCommKit';
import netcore from '@iso/lib/helpers/netcore';
import authActions from '../auth/actions';
import actions from './actions';

const systemId = process.env.REACT_APP_SYSTEM_ID;

export function* search() {
  yield takeEvery('SEARCH', function* ({ payload }) {
    try {
      if (payload.keyword) {
        yield put({
          type: actions.START_SEARCH,
          keyword: payload.keyword,
        });
        yield put({
          type: actions.LOADING_TRANSACTIONS,
        });
        const apiResult = yield searchRequest(payload);
        const result = apiResult.data;
        yield put({
          type: actions.TRANSACTIONS_LOADED,
          transactions: result.data,
          scrollId: result.scrollId,
          transactionCount: result.totalCount,
        });
        // yield put({
        //   type: actions.SET_CURRENT_TRANSACTION,
        //   payload: result.data[0],
        // });
        yield put({
          type: actions.END_SEARCH,
          keyword: payload.keyword,
          searchValue: result.data[0],
        });
      }
    } catch (error) {
      // yield delay(5000);
      yield put({
        type: actions.END_SEARCH,
        keyword: null,
      });
    }
  });
}
export function* saveTag() {
  yield takeEvery('SAVE_TAG', function* ({ payload }) {
    const state = yield select();
    const currentTransaction = state.Transactions.currentTransaction;
    try {
      yield put({
        type: actions.TAG_SAVING,
      });
      const apiResult = yield saveTagRequest(payload, currentTransaction.orgrequestid);
      const result = apiResult.data;
      yield put({
        type: actions.TAG_ADDED,
        id: result.data.Id,
        label: result.data.tagLabel,
        color: result.data.tagColor,
      });
      yield put({
        type: actions.MENU_EVENT_TRIGGERING,
        triggering: false,
      });
    } catch (error) {
      yield put({
        type: actions.TAG_ADD_FAILED,
      });
    }
  });
}
export function* moveTag() {
  yield takeEvery('MOVE_TAG', function* ({ payload }) {
    const state = yield select();
    const currentTransaction = state.Transactions.currentTransaction;
    try {
      yield put({
        type: actions.TAG_MOVING,
      });
      const result = yield moveTagRequest(payload, currentTransaction.orgrequestid);
      const apiResult = result.data;
      if (apiResult.success) {
        yield put({
          type: actions.TAG_MOVED,
        });
        yield put({
          type: actions.MENU_EVENT_TRIGGERING,
          triggering: false,
        });
      } else {
        yield put({
          type: actions.TAG_FOLDER_MESSAGE,
          payload: apiResult.message,
        });
      }
    } catch (error) {
      yield put({
        type: actions.TAG_ADD_FAILED,
      });
    }
  });
}

export function* saveFolder() {
  yield takeEvery('SAVE_FOLDER', function* ({ payload }) {
    const state = yield select();
    const currentTransaction = state.Transactions.currentTransaction;
    try {
      yield put({
        type: actions.FOLDER_SAVING,
      });
      const apiResult = yield saveFolderRequest(payload, currentTransaction.orgrequestid);
      const result = apiResult.data;
      yield put({
        type: actions.FOLDER_ADDED,
        id: result.data.Id,
        label: result.data.folderLabel
      });
      yield put({
        type: actions.MENU_EVENT_TRIGGERING,
        triggering: false,
      });
    } catch (error) {
      yield put({
        type: actions.FOLDER_ADD_FAILED,
      });
    }
  });
}
export function* moveFolder() {
  yield takeEvery('MOVE_FOLDER', function* ({ payload }) {
    const state = yield select();
    const currentTransaction = state.Transactions.currentTransaction;
    const transactions = state.Transactions.transactions;
    const view = state.Transactions.view;
    try {
      yield put({
        type: actions.FOLDER_MOVING,
      });
      const result = yield moveFolderRequest(payload, currentTransaction.orgrequestid);
      const apiResult = result.data;
      if (apiResult.success) {
        yield put({
          type: actions.MENU_EVENT_TRIGGERING,
          triggering: false,
        });
        yield put({
          type: actions.FOLDER_MOVED,
        });
        if (view === 'Folder') {
          // use this code to remove transaction that is move to anothher folder
          const updatedTrx = _.reject(transactions.data, { requestid: currentTransaction.orgrequestid });
          yield put({
            type: actions.TRANSACTIONS_LOADED,
            transactions: updatedTrx.length > 0 ? updatedTrx : [],
          });
          // yield put({
          //   type: actions.SET_CURRENT_TRANSACTION,
          //   payload: updatedTrx[0],
          // });
        }
      } else {
        yield put({
          type: actions.TAG_FOLDER_MESSAGE,
          payload: apiResult.message,
        });
      }
      // yield put({
      //   type: actions.FOLDER_ADDED,
      //   id: toPascalCase(result.data.Id),
      //   label: result.data.folderLabel
      // });
    } catch (error) {
      yield put({
        type: actions.FOLDER_ADD_FAILED,
      });
    }
  });
}
export function* updateFolder() {
  yield takeEvery('UPDATE_FOLDER', function* ({ payload }) {
    try {
      yield put({
        type: actions.FOLDER_UPDATING,
      });
      const apiResult = yield updateFolderRequest(payload);
      const result = apiResult.data;
      yield put({
        type: actions.LOAD_FOLDERS_TRANSACTION,
        customFolders: result.data,
      });
      yield put({
        type: actions.MENU_EVENT_TRIGGERING,
        triggering: false,
      });
    } catch (error) {
      yield put({
        type: actions.FOLDER_UPDATING_FAILED,
      });
    }
  });
}
export function* updateTag() {
  yield takeEvery('UPDATE_TAG', function* ({ payload }) {
    try {
      yield put({
        type: actions.TAG_UPDATING,
      });
      const apiResult = yield updateTagRequest(payload);
      const result = apiResult.data;
      yield put({
        type: actions.LOAD_TAGS_TRANSACTION,
        tags: result.data,
      });
      yield put({
        type: actions.MENU_EVENT_TRIGGERING,
        triggering: false,
      });
    } catch (error) {
      yield put({
        type: actions.TAG_UPDATING_FAILED,
      });
    }
  });
}
export function* fetchFolders() {
  yield takeEvery('LOAD_FOLDERS', function* () {
    const state = yield select();
    const merchantId = state.Auth.identity.merchantId;
    try {
      yield put({
        type: actions.LOADING_FOLDERS,
      });
      const apiResult = yield fetchFoldersRequest(merchantId);
      const result = apiResult.data;
      yield put({
        type: actions.LOAD_FOLDERS_TRANSACTION,
        customFolders: result.data,
      });
      yield put({
        type: actions.LOADING_FOLDERS,
      });
    } catch (error) {
      yield put({
        type: actions.LOAD_FOLDERS_FAILED,
      });
    }
  });
}
export function* fetchTags() {
  yield takeEvery('LOAD_TAGS', function* () {
    const state = yield select();
    const role = state.Auth.identity.role.role.label;
    const merchantId = role !== 'Administrator' && role !== 'Corporate View' && role !== 'Sales' && role !== 'Finance' ? state.Auth.identity.merchantId : state.Auth.selectedMerchant;
    const userId = state.Auth.identity.Id;
    try {
      yield put({
        type: actions.LOADING_TAGS,
      });
      const apiResult = yield fetchTagsRequest(merchantId, userId);
      const result = apiResult.data;
      yield put({
        type: actions.LOAD_TAGS_TRANSACTION,
        tags: result.data,
      });
      yield put({
        type: actions.LOADING_TAGS,
      });
    } catch (error) {
      yield put({
        type: actions.LOAD_TAGS_FAILED,
      });
    }
  });
}
export function* deleteFolder() {
  yield takeEvery('DELETE_FOLDER', function* ({ payload }) {
    try {
      yield put({
        type: actions.FOLDER_DELETING,
      });
      const apiResult = yield deleteFolderRequest(payload.id);
      const result = apiResult.data;
      yield put({
        type: actions.LOAD_FOLDERS_TRANSACTION,
        customFolders: result.data,
      });
      yield put({
        type: actions.FOLDER_DELETED,
      });
    } catch (error) {
      // yield delay(5000); // remove this
      yield put({
        type: actions.FOLDER_DELETE_FAILED,
      });
    }
  });
}
export function* deleteTag() {
  yield takeEvery('DELETE_TAG', function* ({ payload }) {
    const state = yield select();
    const userId = state.Auth.identity.Id;
    try {
      yield put({
        type: actions.TAG_DELETING,
      });
      const apiResult = yield deleteTagRequest(payload.id, userId);
      const result = apiResult.data;
      yield put({
        type: actions.LOAD_TAGS_TRANSACTION,
        tags: result.data,
      });
      yield put({
        type: actions.TAG_DELETED,
      });
    } catch (error) {
      // yield delay(5000); // remove this
      yield put({
        type: actions.TAG_DELETE_FAILED,
      });
    }
  });
}

export function* loadAllTransactions() {
  yield takeEvery('LOAD_ALL_TRANSACTION', function* ({ payload, view }) {
    const state = yield select();
    const role = state.Auth.identity.role.role.label;
    const loadingTransactions = state.Transactions.loadingTransactions;
    const selectedMerchant = (role === 'Administrator' || role === 'Corporate View' || role === 'Finance' || role === 'Sales') ? state.Auth.selectedMerchant : null;
    let statusCode = (role === 'Administrator' || role === 'Corporate View' || role === 'Finance' || role === 'Sales') ? payload !== 'All' ? [payload] : selectedMerchant !== 'All Merchant' ? payload : ['gr033', 'gr001', 'gr002', 'gr018', 'gr003', 'gr152'] : payload;
    if (statusCode && (statusCode === 'gr001' || statusCode[0] === 'gr001')) {
      if (!Array.isArray(statusCode)) {
        statusCode = [statusCode];
      }
      statusCode.push('gr002')
    }
    if (!loadingTransactions) {
      try {
        yield put({
          type: actions.LOADING_TRANSACTIONS,
        });
        const apiResult = yield loadAllTransactionRequest(statusCode, selectedMerchant, view);
        const result = apiResult.data;
        yield put({
          type: actions.TRANSACTIONS_LOADED,
          transactions: result.data.length > 0 ? result.data : [],
          scrollId: result.scrollId,
          transactionCount: result.totalCount,
        });
        // yield put({
        //   type: actions.SET_CURRENT_TRANSACTION,
        //   payload: result.data[0],
        // });
      } catch (error) {
        yield put({
          type: actions.LOAD_TRANSACTIONS_FAILED,
        });
      }
    }
  });
}
export function* loadMoreTransactions() {
  yield takeEvery('LOAD_MORE_TRANSACTIONS', function* ({ payload }) {
    const state = yield select();
    const loadingMoreTransactions = state.Transactions.loadingMoreTransactions;
    const transactionCount = state.Transactions.transactionCount;
    if (!loadingMoreTransactions) {
      try {
        yield put({
          type: actions.LOADING_MORE_TRANSACTIONS,
        });
        const apiResult = yield fetchMoreRequest(payload.scrollId);
        const result = apiResult.data;
        const finalData = payload.transactions.data.concat(result.data);
        yield put({
          type: actions.TRANSACTIONS_LOADED,
          transactions: finalData.length > 0 ? finalData : [],
          scrollId: payload.scrollId,
          transactionCount: transactionCount,
        });
      } catch (error) {
        // yield delay(3000); // remove this
        yield put({
          type: actions.LOAD_TRANSACTIONS_FAILED,
        });
      }
    }
  });
}


export function* fetchEsTrxByStoreId() {
  yield takeEvery('LOAD_ES_TRX_BY_STORE', function* ({ payload }) {
    try {
      yield put({
        type: actions.LOADING_TRANSACTIONS
      });
      const apiResult = yield fetchEsTrxRequest(payload.storeId);
      const result = apiResult.data;
      yield put({
        type: actions.TRANSACTIONS_LOADED,
        transactions: result.data.length > 0 ? result.data : [],
        scrollId: result.scrollId,
        transactionCount: result.totalCount,
      });
      // yield put({
      //   type: actions.SET_CURRENT_TRANSACTION,
      //   payload: result.data[0],
      // });
    } catch (error) {
      yield put({
        type: actions.LOADING_ES_TRX_ERROR,
      });
    }
  });
}
export function* fetchEsTrxByTypeId() {
  yield takeEvery('LOAD_ES_BY_TYPE_ID', function* ({ payload }) {
    const state = yield select();
    const merchantId = state.Auth.identity.merchantId;
    try {
      yield put({
        type: actions.LOADING_TRANSACTIONS
      });
      const apiResult = yield fetchEsTrxByTypeIdRequest(payload, merchantId);
      const result = apiResult.data;
      yield put({
        type: actions.TRANSACTIONS_LOADED,
        transactions: result.data.length > 0 ? result.data : [],
        scrollId: result.scrollId,
        transactionCount: result.totalCount,
      });
      // yield put({
      //   type: actions.SET_CURRENT_TRANSACTION,
      //   payload: result.data[0],
      // });
    } catch (error) {
      yield put({
        type: actions.LOADING_ES_TRX_ERROR,
      });
    }
  });
}

export function* postRemarks() {
  yield takeEvery('POST_REMARKS', function* ({ payload }) {
    const state = yield select();
    const postingRemarks = state.Transactions.remarksPosting;
    const user = state.Auth.identity.username;
    const remarks = payload.remarks;
    payload.remarks = `#USER##${user}##USER#${payload.remarks}`;
    if (!postingRemarks) {
      try {
        yield put({
          type: actions.REMARKS_POSTING,
        });
        // yield delay(5000); // remove this
        const apiResult = yield saveRemarks(user, remarks, payload.request_id);
        yield put({
          type: actions.REMARKS_POSTED,
          requestId: payload.request_id,
          remarks: payload.remarks,
          // date: '2020-09-09 15:00:00' // change this to posting date
          date: apiResult.data
        })
      } catch (error) {
        // yield delay(3000); // remove this
        yield put({
          type: actions.REMARKS_POST_FAILED,
        });
      }
    }
  });
}

export function* issueRefund() {
  yield takeEvery('ISSUE_REFUND', function* ({ payload }) {
    const state = yield select();
    const postingRemarks = state.Transactions.issuingRefund;
    const generate_request = generateReqIdRefund()
    const reqId = payload.refund_id ? payload.refund_id : payload.requestid;
    if (!postingRemarks) {
      try {
        yield put({
          type: actions.ISSUING_REFUND,
        });
        const apiResult = yield refundRequest(
          reqId,
          generate_request,
          payload.amount,
          payload.requestid,
          payload.pchannel,
          '192.168.1.1',
          '',
          payload.responseurl);
        const result = apiResult.data;
        if (result.response_code === 'GR018') {
          yield put({
            type: actions.REFUND_MESSAGE,
            payload: result.response_message,
            refundCode: result.response_code,
          });
          yield put({
            type: actions.REFUND_ISSUED,
            requestId: payload.requestid,
            responseMessage: result.response_message,
            refundCode: result.response_code,
          })
        } else if (result.response_code === 'GR033') {
          yield put({
            type: actions.REFUND_MESSAGE,
            payload: 'Refund Pending',
            pendingRefund: true,
            refundCode: 'GR001', // use this GR001 to not change the status if failed refund occured
          });
          yield put({
            type: actions.REFUND_ISSUED,
            requestId: payload.requestid,
            responseMessage: 'Refund Failed!',
            refundCode: 'GR001', // use this GR001 to not change the status if failed refund occured
          })
        } else {
          yield put({
            type: actions.REFUND_MESSAGE,
            payload: 'Refund Failed!',
            refundCode: 'GR001', // use this GR001 to not change the status if failed refund occured
          });
          yield put({
            type: actions.REFUND_ISSUED,
            requestId: payload.requestid,
            responseMessage: 'Refund Failed!',
            refundCode: 'GR001', // use this GR001 to not change the status if failed refund occured
          })
        }
      } catch (error) {
        yield put({
          type: actions.ISSUE_REFUND_FAILED,
        });
      }
    }
  });
}

export function* cancelTransaction() {
  yield takeEvery('CANCEL_TRANSACTION', function* ({ payload }) {
    const state = yield select();
    const cancellingTransaction = state.Transactions.cancellingTransaction;
    if (!cancellingTransaction) {
      try {
        yield put({
          type: actions.CANCELLING_TRANSACTION,
        });
        const apiResult = yield cancelTrx({ requestId: payload.requestId });
        if (apiResult.status === 200 || apiResult.status === 201) {
          const result = apiResult.data;
          yield put({
            type: actions.CANCELED_TRANSACTION,
            transaction: result.data,
            message: `Successfully cancelled transaction ${result.data.requestid}`,
          });
        } else {
          yield put({
            type: actions.CANCEL_TRANSACTION_FAILED,
            message: apiResult.message,
          });
        }
      } catch (error) {
        yield put({
          type: actions.CANCEL_TRANSACTION_FAILED,
          message: 'Failed to cancel transaction.'
        });
      }
    }
  });
}

export function* downloadTrxPdf() {
  yield takeEvery('DOWNLOAD_TRANSACTION_PDF', function* ({ payload }) {
    try {
      yield put({
        type: actions.DOWNLOADING_TRANSACTION_PDF,
      });
      const apiResult = yield fetchTrxPdf(payload);
      const result = apiResult.data;
      if (apiResult.status === 200) {
        yield put({
          type: actions.DOWNLOADED_TRANSACTION_PDF,
          payload: result,
        });
      } else {
        yield put({
          type: actions.DOWNLOAD_TRANSACTION_PDF_FAILED,
          message: 'Failed to download transaction.',
        });
      }
    } catch (error) {
      yield put({
        type: actions.DOWNLOAD_TRANSACTION_PDF_FAILED,
        message: 'Failed to download transaction.',
      });
    }
  });
}

function generateReqIdRefund() {
  let id = '';
  const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';

  for (let i = 0; i < 10; i++) {
    id += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return `REFUND${id}`;
}

export function* mqttIncommingTrx() {
  yield takeEvery('MQTT_INCOMMING_TRX', function* ({ payload }) {
    try {
      yield put({
        type: actions.LOADING_TRANSACTIONS,
      });
      yield put({
        type: actions.MQTT_TRX,
        payload: null,
      });
      yield put({
        type: actions.TRANSACTIONS_LOADED,
        transactions: payload,
      });
    } catch (error) {
      // yield delay(5000); // remove this
      yield put({
        type: actions.LOAD_TRANSACTIONS_FAILED,
      });
    }
  });
}
// export function* setView() {
//   yield takeEvery('SET_VIEW', function* ({ payload }) {
//     try {
//       yield put({
//         type: actions.SETTING_VIEW,
//         view: payload,
//       });
//     } catch (error) {
//       yield put({
//         type: actions.CONNECT_FAILED,
//       });
//     }
//   });
// }

function refundRequest(org_trxid, request_id, amount, biz_reqid, pchannel, ip_address, notification_url, response_url) {
  const data = {
    org_trxid,
    request_id,
    amount,
    biz_reqid,
    pchannel,
    ip_address,
    notification_url,
    response_url
  };

  return post(`refund`, data);
}

function cancelTrx(data) {
  return post(`temptransactions/cancel_transaction`, data);
}

export function* loadStoreAssets() {
  yield takeEvery('LOAD_STORE_INVENTORY', function* ({ payload }) {
    // const state = yield select();
    // const merchantId = state.Auth.identity.merchantId;
    const state = yield select();
    const identity = state.Auth.identity;
    try {
      yield put({
        type: actions.RESET_STORE_INVENTORY
      });

      yield put({
        type: actions.RESET_ALLOWED_PCHANNEL
      });
      yield put({
        type: actions.LOADING_STORE_INVENTORY
      });
      const apiResult = identity ? yield fetchStoreInventory(payload.storeId) : yield fetchStoreInventoryEncrypted(payload.storeId);
      const result = apiResult.data;
      if (apiResult.data.success) {
        yield put({
          type: actions.STORE_INVENTORY_LOADED,
          inventory: result.data.length > 0 ? result.data : [],
        });

        try {
          yield put({
            type: actions.LOADING_ALLOWED_PCHANNEL
          });
          const apiResult = identity ? yield fetchAllowedChannels(payload.storeId) : yield fetchAllowedChannelsEncrypted(payload.storeId);
          const result = apiResult.data;
          yield put({
            type: actions.ALLOWED_PCHANNEL_LOADED,
            pchannels: result.data.length > 0 ? result.data : [],
          });
        } catch (err) {
          yield put({
            type: actions.LOAD_ALLOWED_PCHANNEL_FAILED,
          });
        }
      } else {
        yield put({
          type: actions.LOAD_STORE_INVENTORY_FAILED,
        });
      }
    } catch (error) {
      yield put({
        type: actions.LOAD_STORE_INVENTORY_FAILED,
      });
    }
  });
}

export function* countStoreInventory() {
  yield takeEvery('COUNT_STORE_INVENTORY', function* ({ payload }) {
    // const state = yield select();
    // const merchantId = state.Auth.identity.merchantId;
    yield put({
      type: actions.COUNTING_STORE_INVENTORY,
    });
    try {
      const apiResult = yield fetchStoreInventory(payload.storeId);
      const result = apiResult.data;
      if (apiResult.data.success) {
        yield put({
          type: actions.COUNT_STORE_INVENTORY_RESULT,
          count: {
            storeId: payload.storeId,
            count: result.data.length,
          }
        });
      }
    /**
     * @param  {} error
     * TODO add a handler for catch
     */
    } catch (error) {
    }
  });
}

export function* deleteFolderTxn() {
  yield takeEvery('DELETE_FOLDER_TRANSACTION', function* ({ payload, folderId }) {
    const state = yield select();
    const transactions = state.Transactions.transactions;
    const view = state.Transactions.view;
    try {
      yield put({
        type: actions.FOLDER_TRANSACTION_DELETING,
        paylod: null,
      });
      const apiResult = yield deleteFolderTxnRequest(payload.requestid, folderId);
      const result = apiResult.data;
      if (result.success) {
        yield put({
          type: actions.FOLDER_TRANSACTION_DELETED,
          payload: `Folder Transaction Deleted!`,
        });
        if (view === 'Folder') {
          // use this code to remove transaction that is move to anothher folder
          const updatedTrx = _.reject(transactions.data, { requestid: payload.requestid });
          yield put({
            type: actions.TRANSACTIONS_LOADED,
            transactions: updatedTrx.length > 0 ? updatedTrx : [],
          });
          // yield put({
          //   type: actions.SET_CURRENT_TRANSACTION,
          //   payload: updatedTrx[0],
          // });
        }
      } else {
        yield put({
          type: actions.FOLDER_TRANSACTION_DELETE_FAILED,
        });
      }
    } catch (error) {
      yield put({
        type: actions.FOLDER_TRANSACTION_DELETE_FAILED,
      });
    }
  });
}

export function* removeTransactionTag() {
  yield takeEvery('REMOVE_TXN_TAG', function* ({ payload, tag_id }) {
    const state = yield select();
    const transactions = state.Transactions.transactions;
    // const tags = state.Transactions.tags;
    const view = state.Transactions.view;
    try {
      yield put({
        type: actions.REMOVING_TXN_TAG,
        paylod: null,
      });
      const apiResult = yield removeTxnTagRequest(payload.requestid, tag_id);
      const result = apiResult.data;
      if (result.success) {
        yield put({
          type: actions.TXN_TAG_REMOVED,
          payload: `Transaction removed from tag!`,
        });
        if (view === 'Tag') {
          // use this code to remove transaction that is move to anothher folder
          const updatedTrx = _.reject(transactions.data, { requestid: payload.requestid });
          yield put({
            type: actions.TRANSACTIONS_LOADED,
            transactions: updatedTrx.length > 0 ? updatedTrx : [],
          });
          // yield put({
          //   type: actions.SET_CURRENT_TRANSACTION,
          //   payload: updatedTrx[0],
          // });
        }
      } else {
        yield put({
          type: actions.TXN_TAG_REMOVE_FAILED,
        });
      }
    } catch (error) {
      yield put({
        type: actions.TXN_TAG_REMOVE_FAILED,
      });
    }
  });
}

export function* loadProvice() {
  yield takeEvery('LOAD_PROVINCE', function* () {
    const state = yield select();
    const identity = state.Auth.identity;
    try {
      yield put({
        type: actions.RESET_PROVINCE
      });
      yield put({
        type: actions.LOADING_PROVINCE
      });
      const apiResult = identity ? yield fetchProvince() : yield fetchProvinceEncrypted();
      const result = apiResult.data;
      yield put({
        type: actions.PROVINCE_LOADED,
        provinces: result.data.length > 0 ? result.data : [],
      });
    } catch (error) {
      yield put({
        type: actions.LOAD_PROVINCE_FAILED,
      });
    }
  });
}
export function* loadProviceShipping() {
  yield takeEvery('LOAD_PROVINCE_S', function* (payload) {
    const merchantId = payload ? payload.payload : '';
    const state = yield select();
    let identity = state.Auth.identity;
    try {
      yield put({
        type: actions.RESET_PROVINCE_S
      });
      yield put({
        type: actions.LOADING_PROVINCE_S
      });
      const apiResult = identity ? yield fetchProvinceShipping(merchantId) : yield fetchProvinceShippingEncrypted(merchantId);
      const result = apiResult.data;
      yield put({
        type: actions.PROVINCE_LOADED_S,
        provinces: result.data.length > 0 ? result.data : [],
      });
    } catch (error) {
      yield put({
        type: actions.LOAD_PROVINCE_FAILED_S,
      });
    }
  });
}
export function* loadCities() {
  yield takeEvery('LOAD_CITY', function* ({ payload }) {
    const state = yield select();
    let identity = state.Auth.identity;
    try {
      yield put({
        type: actions.RESET_CITY
      });
      yield put({
        type: actions.LOADING_CITY
      });
      const apiResult = identity ? yield fetchCity(payload.province) : yield fetchCityEncrypted(payload.province);
      const result = apiResult.data;
      yield put({
        type: actions.CITY_LOADED,
        cities: result.data.length > 0 ? result.data : [],
      });
    } catch (error) {
      yield put({
        type: actions.LOAD_CITY_FAILED,
      });
    }
  });
}
export function* loadCitiesShipping() {
  yield takeEvery('LOAD_CITY_S', function* ({ payload }) {
    const state = yield select();
    let identity = state.Auth.identity;
    try {
      yield put({
        type: actions.RESET_CITY_S
      });
      yield put({
        type: actions.LOADING_CITY_S
      });
      const apiResult = identity ? yield fetchCity(payload.province) : yield fetchCityEncrypted(payload.province)
      const result = apiResult.data;
      yield put({
        type: actions.CITY_LOADED_S,
        cities: result.data.length > 0 ? result.data : [],
      });
    } catch (error) {
      yield put({
        type: actions.LOAD_CITY_FAILED_S,
      });
    }
  });
}
export function* loadBrgys() {
  yield takeEvery('LOAD_BRGY', function* ({ payload }) {
    const state = yield select();
    let identity = state.Auth.identity;
    try {
      yield put({
        type: actions.RESET_BRGY
      });
      yield put({
        type: actions.LOADING_BRGY
      });
      const apiResult = identity ? yield fetchBrgy(payload.province, payload.city) : yield fetchBrgyEncrypted(payload.province, payload.city);
      const result = apiResult.data;
      yield put({
        type: actions.BRGY_LOADED,
        brgys: result.data.length > 0 ? result.data : [],
      });
    } catch (error) {
      yield put({
        type: actions.LOAD_BRGY_FAILED,
      });
    }
  });
}
export function* loadBrgysShipping() {
  yield takeEvery('LOAD_BRGY_S', function* ({ payload }) {
    const state = yield select();
    let identity = state.Auth.identity;
    try {
      yield put({
        type: actions.RESET_BRGY_S
      });
      yield put({
        type: actions.LOADING_BRGY_S
      });
      const apiResult = identity ? yield fetchBrgy(payload.province, payload.city) : yield fetchBrgyEncrypted(payload.province, payload.city);
      const result = apiResult.data;
      yield put({
        type: actions.BRGY_LOADED_S,
        brgys: result.data.length > 0 ? result.data : [],
      });
    } catch (error) {
      yield put({
        type: actions.LOAD_BRGY_FAILED_S,
      });
    }
  });
}
export function* loadTempTransaction() {
  yield takeEvery('LOAD_TEMP_TRANSACTION', function* ({ payload }) {
    const state = yield select();
    let identity = state.Auth.identity;
    try {
      // yield put({
      //   type: actions.RESET_BRGY_S
      // });
      yield put({
        type: actions.LOADING_TEMP_TRANSACTION
      });
      const statusResult = identity ? yield checkTrxStatus(payload) : yield checkTrxStatusEncrypted(payload);
      if (statusResult.data.success) {
        if (statusResult.data.data.response_code !== 'GR033') {
          yield put({
            type: actions.TEMP_TRANSACTION_LOADED,
            data: null,
            dataId: null,
            storeData: null,
            statusResult: statusResult.data.data,
            customerChoosePmethod: null,
            template: null
          });
        } else {
          const apiResult = identity ? yield fetchTempTransaction(payload) : yield fetchTempTransactionEncrypted(payload);
          const result = apiResult.data;
          if (result.success) {
            const tempData = JSON.parse(result.data.tempTransaction);
            const tempDataId = result.data.Id;
            const apiStoreResult = identity ? yield fetchStoreData(tempData.storeId) : yield fetchStoreDataEncrypted(tempData.storeId);
            const storeResult = apiStoreResult.data;
            const templateData = {
              merchant_id: storeResult.data.merchantId,
              type: 'PAYLINK'
            }
            const template = identity ? yield fetchFormTemplate(templateData) : yield fetchFormTemplateEncrypted(templateData);
            const imgReqData = storeResult.data.fileName;
            const imgResult = identity? yield fetchImageRequest(imgReqData) : yield fetchImageRequestEncrypted(imgReqData);
            const finalBase64 = imgResult.data.base64 !== 'NO IMAGE FOUND' ? `data:${storeResult.data.fileName};base64,${imgResult.data.base64}` : null;
            storeResult.data = {
              ...storeResult.data,
              merchantLogo: finalBase64,
            };
            if (storeResult.success) {
              yield put({
                type: actions.TEMP_TRANSACTION_LOADED,
                data: JSON.parse(result.data.tempTransaction),
                dataId: tempDataId,
                storeData: storeResult.data,
                statusResult: statusResult.data.data,
                customerChoosePmethod: tempData.customerChoosePmethod,
                template: template.data.template
              });
            }
          } else {
            yield put({
              type: actions.LOAD_TEMP_TRANSACTION_FAILED,
            });
          }
        }
      } else {
        yield put({
          type: actions.LOAD_TEMP_TRANSACTION_FAILED,
        });
      }
    } catch (error) {
      yield put({
        type: actions.LOAD_TEMP_TRANSACTION_FAILED,
      });
    }
  });
}
export function* submitTempTrx() {
  yield takeEvery('SUBMIT_TEMP_TRX', function* ({ payload }) {
    try {
      yield put({
        type: actions.SUBMITTING_TEMP_TRX
      });
      const state = yield select();
      let apiResult;
      if (payload.directPush) {
        const data = payload.data;
        delete data.customerChoosePmethod
        delete data.customerInputInfo
        data.redirect_payment_channel = `${window.location.origin}/redirect_payment_channel/${payload.data.transaction.request_id}`;
        apiResult = yield submitKrakenTransaction(data);
      } else {
        apiResult = yield submitTempTransaction(payload.data);
      }
      const result = apiResult.data;
      if (result.success) {
        yield put({
          type: actions.TEMP_TRX_SUBMITTED,
          payload: result.data,
        });
        // let url;
        // if (payload.directPush) {
        //   url = result.data.payment_action_info;
        // } else {
        //   url = result.data.paymentLinkUrl;
        // }
        // yield put({
        //   type: actions.URL,
        //   payload: url,
        // });

        // NETCORE START
        netcore({
          type: 'activity',
          email: state.Auth.identity.email,
          activityName: 'transaction_created_merchant',
          activityData: {
            transaction_link: payload.directPush ? result.data.payment_action_info : result.data.paymentLinkUrl
          }
        });
        // NETCORE END
      } else {
        yield put({
          type: actions.SUBMIT_TEMP_TRX_FAILED,
        });
        yield put({
          type: actions.TEMP_ERROR_MESSAGE,
          payload: result.message.includes('Validation Error:') ? 'Invalid request body' : result.message,
        });
      }
    } catch (error) {
      yield put({
        type: actions.SUBMIT_TEMP_TRX_FAILED,
      });
    }
  });
}
export function* redirectCustomerSubmitTrx() {
  yield takeEvery('SUBMIT_TRX_REDIRECT_CUSTOMER', function* ({ payload }) {
    const state = yield select();
    const trxTempId = state.Transactions.tempTransactionId;
    const customerChoosePmethod = state.Transactions.customerChoosePmethod;
    const identity = state.Auth.identity;
    try {
      yield put({
        type: actions.SUBMITTING_REDIRECT_CUSTOMER_TRX
      });
      const apiResult = yield submitKrakenTransactionCustomer(payload);
      const updatedTempTrx = identity ? yield saveTransactionCustomer(trxTempId, payload, customerChoosePmethod) : yield saveTransactionCustomerEncrypted(trxTempId, payload, customerChoosePmethod);
      const result = apiResult.data;
      const resultUpdatedTemp = updatedTempTrx.data;
      if (resultUpdatedTemp.success) {
        yield put({
          type: actions.UPDATED_TRX_TEMP,
        });
      }
      if (result.success) {
        yield put({
          type: actions.REDIRECT_CUSTOMER_TRX_SUBMITTED,
          payload: result.data,
        });
      } else {
        yield put({
          type: actions.SUBMIT_TRX_REDIRECT_CUSTOMER_ERROR,
          payload: result.message.includes('Validation Error:') ? 'Invalid request body' : result.message,
        });
        yield put({
          type: actions.SUBMIT_TEMP_TRX_FAILED,
          payload: result.message.includes('Validation Error:') ? 'Invalid request body' : result.message,
        });
        yield put({
          type: actions.TEMP_ERROR_MESSAGE,
          payload: result.message.includes('Validation Error:') ? 'Invalid request body' : result.message,
        });
      }
    } catch (error) {
      yield put({
        type: actions.SUBMIT_TEMP_TRX_FAILED,
      });
      yield put({
        type: actions.SUBMIT_TRX_REDIRECT_CUSTOMER_ERROR,
      });
    }
  });
}
export function* getAccountThreshold() {
  yield takeEvery('GET_THRESHOLD', function* ({ payload }) {
    try {

      const state = yield select();
      const loading = state.Transactions.thresholdLoading;
      if (!loading) {
        yield put({
          type: actions.GETTING_THRESHOLD
        });

        const merchantId = state.Auth.identity.merchantId;
        const apiResult = yield getLimit(merchantId);

        const result = apiResult.data;
        if (result) {
          yield put({
            type: actions.GOT_THRESHOLD,
            payload: result,
          });
        } else {
          yield put({
            type: actions.GETTING_THRESHOLD_FAILED,
          });
        }
      }
    } catch (error) {
      yield put({
        type: actions.GETTING_THRESHOLD_FAILED,
      });
    }
  });
}
export function* submitApplication() {
  yield takeEvery('SUBMIT_APPLICATION', function* ({ payload }) {
    try {
      yield put({
        type: actions.SUBMITTING_APPLICATION,
      });
      const apiResult = yield createFullyRegistered(payload);
      const result = apiResult.data;
      if (result.success) {
        yield put({
          type: actions.APPLICATION_SUBMITTED,
          payload: true,
        });
        const moduleResult = yield fetchModules();
        const modResult = moduleResult.data;
        const authValue = result.data.role.authorizations;
        let formattedAuthorization = [];
        let formattedModuleId = {};
        for (let i = 0; i < authValue.length; i++) {
          const element = authValue[i];
          formattedModuleId[element.moduleId] = element;
        }
        formattedAuthorization.push(formattedModuleId);
        if (modResult.success) {
          let formattedModule = [];
          let formatteElement = {};
          const modulesData = modResult.data.result[0].submodules;
          for (let i = 0; i < modulesData.length; i++) {
            const element = modulesData[i];
            formatteElement[element.alias] = element;
          }
          formattedModule.push(formatteElement);
          yield put({
            type: authActions.SUBMODULES,
            payload: modResult.data.result[0].submodules,
          });

          const apiResultRoles = yield fetchAllRoles();
          const resultRoles = apiResultRoles.data;
          const storesResult = yield fetchStores(result.data.merchantId)
          if (storesResult.status === 200) {
            const role = result.data.role.role.label;
            let topic;
            if (role === 'Store Admin' || role === 'Store User' || role === 'Ticket Seller' || role === 'Shipping Seller' || role === 'Conductor') {
              topic = `/lambda-transaction-${storesResult.data[0].storeId}`;
            } else if (role === 'Merchant Admin' || role === 'Cashier' || role === 'Audit') {
              topic = `/lambda-transaction-${result.data.merchantId}`;
            }
            yield put({
              type: authActions.FULLY_REGISTERED,
              identity: JSON.stringify(result.data),
              stores: storesResult.data ? JSON.stringify(storesResult.data) : null,
              modules: JSON.stringify(modResult.data.result[0].submodules),
              merchantId: result.data.merchantId,
              customAuthorizations: JSON.stringify(formattedAuthorization[0]),
              customModules: JSON.stringify(formattedModule[0]),
              roles: JSON.stringify(resultRoles.data),
              profile: 'Profile',
              topic: topic,
              preRegistered: false,
            });
            yield put({
              type: actions.APPLICATION_SUBMITTED,
              payload: true,
            });
            const module = JSON.stringify(modResult.data.result[0].submodules);
            const identity = JSON.stringify(result.data);
            const stores = storesResult.data ? JSON.stringify(storesResult.data) : null;
            const customAuthorizations = JSON.stringify(formattedAuthorization[0]);
            const customModules = JSON.stringify(formattedModule[0]);
            const roles = JSON.stringify(resultRoles.data);
            yield localStorage.setItem('modules', module);
            yield localStorage.setItem('identity', identity);
            yield localStorage.setItem('stores', stores);
            yield localStorage.setItem('topic', topic);
            yield localStorage.setItem('customAuthorizations', customAuthorizations);
            yield localStorage.setItem('customModules', customModules);
            yield localStorage.setItem('roles', roles);
            pushDataToApp('modules', module);
            pushDataToApp('identity', identity);
            pushDataToApp('stores', stores);
            pushDataToApp('topic', topic);
            pushDataToApp('customAuthorizations', customAuthorizations);
            pushDataToApp('customModules', customModules);
            pushDataToApp('roles', roles);
          }
        }
        
        // NETCORE START
        netcore({
          type: 'activity',
          email: payload.email,
          activityName: 'business_ac_success',
          activityData: {
            business_name: payload.company_name
          }
        })
        const kycUrl = result.data.userSettings.find(item => item.label === 'kyc_url')
        if (kycUrl) {
          netcore({
            type: 'activity',
            email: payload.email,
            activityName: 'kyc_generated',
            activityData: {
              kyc_url: kycUrl.value
            }
          })
        }
        netcore({
          type: 'contact',
          email: payload.email,
          contactData: {
            'mobile': Number(payload.mobile),
            'PROVINCE': payload.state,
            'CITY': payload.city,
            'KYC_URL': kycUrl.value ? kycUrl.value : null,
            'STORE_ID': result.data.role.role.label,
            'STORE_NAME': result.data.role.role.label,
          }
        })
        // NETCORE END
      } else {
        yield put({
          type: actions.SUBMITTING_APPLICATION_ERROR,
          payload: result.message ? result.message : 'Error on saving!'
        });
        yield put({
          type: actions.SUBMITTING_APPLICATION_FAILED,
        });
      }
    } catch (error) {
      yield put({
        type: actions.SUBMITTING_APPLICATION_FAILED,
      });
    }
  });
}

export function* submitTemporaryTransaction() {
  yield takeEvery('SUBMIT_TEMP_TRANSACTION', function* ({ payload }) {
    try {
      yield put({
        type: actions.SUBMITTING_TEMP_TRANSACTION,
      });
      const apiResult = yield submitTempTransaction(payload);
      const result = apiResult.data;
      if (result.success) {
        yield put({
          type: actions.SUBMITTED_TEMP_TRANSACTION,
          payload: result.data,
        });
      } else {
        yield put({
          type: actions.SUBMIT_TEMP_TRANSACTION_FAILED,
          payload: result.message,
        });
      }
    } catch (error) {
      yield put({
        type: actions.SUBMIT_TEMP_TRANSACTION_FAILED,
        payload: error,
      });
    }
  });
}

export function* getRedirectTrxStatus() {
  yield takeEvery('FETCH_TRX_STATUS', function* (payload) {
    try {
      const apiResult = yield checkTrxStatus(payload.payload);
      const result = apiResult.data;
      yield put({
        type: actions.FETCHING_TRX_STATUS,
      });
      yield put({
        type: actions.FETCHED_TRX_STATUS,
        result: result.data,
      });
    } catch (error) {
      yield put({
        type: actions.FETCH_TRX_STATUS_FAILED,
      });
    }
  });
}

export function* searchAuditLogs() {
  yield takeEvery('SEARCH_AUDITLOGS', function* (payload) {
    try {
      const apiResult = yield searchAudit(payload.payload);
      const result = apiResult.data;
      yield put({
        type: actions.SEARCHING_AUDITLOGS,
      });
      yield put({
        type: actions.SEARCHED_AUDITLOGS,
        result: result.data,
        count: result.data.count,
      });
    } catch (error) {
      yield put({
        type: actions.SEARCH_AUDITLOGS_FAILED,
      });
    }
  });
}

export function* getAuditLogs() {
  yield takeEvery('GET_AUDITLOGS', function* ({payload}) {
    try {
      yield put({
        type: actions.GETTING_AUDITLOGS,
      });
      const auditLogsData = yield select(state => state.Transactions.gotAuditLogs);
      const apiResult = yield fetchAuditLogs(payload);
      const result = apiResult.data;
      if (result.success){
        const data = result.data.data
        if (!payload.loadMore){
          yield put({
            type: actions.GOT_AUDITLOGS_WO_LOADMORE,
            logsdata: data.logsdata,
            logscount: data.logscount,
            logstotalcount: data.logstotal,
            message: result.data.response_message,
          });
        } else {
          const newData = auditLogsData.concat(data);
          yield put({
            type: actions.GOT_AUDITLOGS_W_LOADMORE,
            logsdata: newData,
            message: result.data.response_message,
          });
        }
      } else {
        yield put({
          type: actions.GETTING_AUDITLOGS_FAILED,
          message: `${result.data.response_message}. Please try again!`,
        });
      }
    } catch (error) {
      yield put({
        type: actions.GETTING_AUDITLOGS_FAILED,
        message: 'Something went wrong. Please try again!',
      });
    }
  });
}

export function* searchMerchantAuditLogs() {
  yield takeEvery('SEARCH_MERCHANT_AUDITLOGS', function* (payload) {
    try {
      const apiResult = yield searchMerchantAudit(payload.payload);
      const result = apiResult.data;
      yield put({
        type: actions.SEARCHING_MERCHANT_AUDITLOGS,
      });
      yield put({
        type: actions.SEARCHED_MERCHANT_AUDITLOGS,
        result: result.data,
        count: result.data.count,
      });
    } catch (error) {
      yield put({
        type: actions.SEARCH_MERCHANT_AUDITLOGS_FAILED,
      });
    }
  });
}
export function* fetchMoreLogs() {
  yield takeEvery('LOAD_MORE_LOGS', function* (payload) {
    const state = yield select();
    const loadingMoreLogs = state.Transactions.loadingMoreLogs;
    if (!loadingMoreLogs) {
      try {
        yield put({
          type: actions.LOADING_MORE_LOGS,
        });
        const apiResult = yield loadMoreLogs(payload.payload.scroll_id);
        const result = apiResult.data;
        const finalResult = payload.payload.logs.data ? payload.payload.logs.data.concat(result.data) :
          payload.payload.logs.concat(result.data);
        yield put({
          type: actions.LOADED_MORE_LOGS,
          // result: result.data,
          result: finalResult,
        });
      } catch (error) {
        yield put({
          type: actions.LOAD_MORE_LOGS_FAILED,
        });
      }
    }
  });
}
export function* fetchMoreMerchantLogs() {
  yield takeEvery('LOAD_MORE_MERCHANT_LOGS', function* (payload) {
    const state = yield select();
    const loadingMoreMerchantLogs = state.Transactions.loadingMoreMerchantLogs;
    if (!loadingMoreMerchantLogs) {
      try {
        yield put({
          type: actions.LOADING_MORE_MERCHANT_LOGS,
        });
        const apiResult = yield loadMoreMerchantLogs(payload.payload.scroll_id);
        const result = apiResult.data;
        const finalResult = payload.payload.logs.data ? payload.payload.logs.data.concat(result.data) :
          payload.payload.logs.concat(result.data);
        yield put({
          type: actions.LOADED_MORE_MERCHANT_LOGS,
          // result: result.data,
          result: finalResult,
        });
      } catch (error) {
        yield put({
          type: actions.LOAD_MORE_MERCHANT_LOGS_FAILED,
        });
      }
    }
  });
}
export function* fetchPaymentFees() {
  yield takeEvery('FETCH_PAYMENT_FEES', function* () {
    const state = yield select();
    const merchantId = state.Auth.identity.merchantId;
    try {
      yield put({
        type: actions.FETCHING_PAYMENT_FEES,
      });
      const apiResult = yield fetchPaymentFeesRequest(merchantId);
      const result = apiResult.data;
      const apiResultB = yield fetchMerchantPaymentFeesRequest(merchantId);
      const resultB = apiResultB.data;
      if (apiResult.status === 200) {
        yield put({
          type: actions.FETCH_PAYMENT_FEES_SUCCESS,
          result: result,
          payload: resultB.data,
        });
      }
    } catch (error) {
      yield put({
        type: actions.FETCH_PAYMENT_FEES_FAILED,
      });
    }
  });
}

export function* fetchTxnFees() {
  yield takeEvery('FETCH_TRANSACTION_FEE', function* (payload) {
    try {
      const apiResult = yield fetchFees(payload.payload);
      const result = apiResult.data;
      const template = yield fetchFormTemplate({
        merchant_id: payload.payload,
        type: 'PAYLINK'
      });
      yield put({
        type: actions.FETCHING_TRANSCTION_FEE,
      });
      yield put({
        type: actions.FETCHED_TRANSACTION_FEE,
        result: result,
        merchantTemplate: template.data.template
      });
    } catch (error) {
      yield put({
        type: actions.FETCH_TRANSACTION_FEE_FAILED,
      });
    }
  });
}

export function* sendSmsLink() {
  yield takeEvery('SEND_SMS_LINK', function* ({ payload }) {
    try {
      yield put({
        type: actions.SENDING_SMS_LINK
      });
      const apiResult = yield requestSmsLink(payload);
      const result = apiResult.data;
      if (result.success) {
        yield put({
          type: actions.SENT_SMS_LINK,
          message: result.data,
        });
      } else {
        yield put({
          type: actions.SENDING_SMS_LINK_FAILED,
          message: result.data,
        });
      }
    } catch (error) {
      yield put({
        type: actions.SENDING_SMS_LINK_FAILED,
        message: `Failed to send payment link to ${payload.mobileNumber}`
      });
    }
  });
}

export function* fetchBocTrxData() {
  yield takeEvery('FETCH_BOC_TRANSACTION_DATA', function* ({ payload }) {
    try {
      yield put({
        type: actions.FETCHING_BOC_TRANSACTION_DATA
      });
      const apiResult = yield fetchDetailedTrxDataRequest(payload);
      const result = apiResult.data;
      if (result.success) {
        yield put({
          type: actions.FETCHED_BOC_TRANSACTION_DATA,
          payload: result.data,
        });
      } else {
        yield put({
          type: actions.FETCH_BOC_TRANSACTION_DATA_FAILED,
          message: result.data,
        });
      }
    } catch (error) {
      yield put({
        type: actions.FETCH_BOC_TRANSACTION_DATA_FAILED,
        message: `Fetching BOC data failed`
      });
    }
  });
}

export function* fetchDetailedTrxData() {
  yield takeEvery('FETCH_DETAILED_TRANSACTION_DATA', function* ({ payload }) {
    try {
      yield put({
        type: actions.FETCHING_DETAILED_TRANSACTION_DATA
      });
      const apiResult = yield fetchDetailedTrxDataRequest(payload);
      const result = apiResult.data;
      const finalBase64 = `data:${result.data.fileType};base64,${result.data.merchantLogo.base64}`;
      if (result.success) {
        if(result.data?.responseCode !== 'GR033') {
          yield put({
            type: actions.FETCHED_DETAILED_TRANSACTION_DATA,
            payload: result.data,
            merchantLogo: finalBase64,
            hasMerchantLogo: result.data.merchantLogo.success,
          });
        }
      } else {
        yield put({
          type: actions.FETCH_DETAILED_TRANSACTION_DATA_FAILED,
          message: result.data,
        });
      }
    } catch (error) {
      yield put({
        type: actions.FETCH_DETAILED_TRANSACTION_DATA_FAILED,
        message: `Fetching Detailed data failed`
      });
    }
  });
}

export function* fetchPaymentTransaction() {
  yield takeEvery('FETCH_TRANSACTION_PAYMENT', function* ({ payload }) {
    const state = yield select();
    let identity = state.Auth.identity;
    try {
      yield put({
        type: actions.FETCHING_TRANSACTION_PAYMENT
      });
      const apiResult = identity ? yield fetchingPaymentTransaction(payload) : yield fetchingPaymentTransactionEncrypted(payload);
      const result = apiResult.data;
      if (result.success) {
        yield put({
          type: actions.FETCHED_TRANSACTION_PAYMENT,
          payload: result.data,
        });
      }
    } catch (error) {
      yield put({
        type: actions.FETCH_TRANSACTION_PAYMENT_FAILED,
        message: `Fetching Detailed data failed`
      });
    }
  });
}

export function* validateApplication() {
  yield takeEvery('VALIDATE_APPLICATION', function* (payload) {
    try {
      yield put({
        type: actions.VALIDATING_APPLICATION,
      });
      const apiResult = yield validateApp(payload.payload);
      const result = apiResult.data;
      yield put({
        type: actions.VALIDATED_APPLICATION,
        result: result,
      });
    } catch (error) {
      yield put({
        type: actions.VALIDATE_APPLICATION_FAILED,
      });
    }
  });
}
export function* fetchShippingSetup() {
  yield takeEvery('FETCH_SHIPPING_SETUP', function* (payload) {
    try {
      yield put({
        type: actions.FETCHING_SHIPPING_SETUP,
      });
      const apiResult = yield fetchingShippingSetup(payload.payload);
      const result = apiResult.data;
      yield put({
        type: actions.FETCHED_SHIPPING_SETUP,
        payload: result.data,
      });
    } catch (error) {
      yield put({
        type: actions.FETCH_SHIPPING_SETUP_FAILED,
      });
    }
  });
}

export function* getRefundValidator() {
  yield takeEvery('FETCH_REFUND_VALIDATOR', function* (payload) {
    try {
      yield put({
        type: actions.FETCHING_REFUND_VALIDATOR,
      });
      const apiResult = yield fetchRefundValidatorByPchannel(payload.payload);
      const result = apiResult.data;
      yield put({
        type: actions.FETCHED_REFUND_VALIDATOR,
        payload: result.data,
      });
    } catch (error) {
      yield put({
        type: actions.FETCH_REFUND_VALIDATOR_FAILED,
      });
    }
  });
}
export function* fetchCustomFeeCalculator() {
  yield takeEvery('FETCH_CUSTOM_FEE_CALCULATOR', function* (payload) {
    try {
      yield put({
        type: actions.FETCHING_CUSTOM_FEE_CALCULATOR,
      });
      const apiResult = yield fetchingCustomFeeCalculator(payload.payload);
      const result = apiResult.data;
      yield put({
        type: actions.FETCHED_CUSTOM_FEE_CALCULATOR,
        payload: result.data,
      });
    } catch (error) {
      yield put({
        type: actions.FETCH_CUSTOM_FEE_CALCULATOR_FAILED,
      });
    }
  });
}

export function* fetchItemBodyData() {
  yield takeEvery('FETCH_ITEM_BODY', function* (payload) {
    const state = yield select();
    const identity = state.Auth.identity;
    try {
      yield put({
        type: actions.FETCHING_ITEM_BODY,
      });
      const apiResult = identity ? yield fetchItemBodyRequest(payload.payload) : fetchItemBodyRequestEncrypted(payload.payload);
      const result = apiResult.data;
      yield put({
        type: actions.FETCHED_ITEM_BODY,
        result: result.data,
      });
    } catch (error) {
      yield put({
        type: actions.FETCH_ITEM_BODY_FAILED,
      });
    }
  });
}

export function* fetchBookingRequestData() {
  yield takeEvery('FETCH_BOOKING_REQUEST', function* (payload) {
    const state = yield select();
    const identity = state.Auth.identity;
    try {
      yield put({
        type: actions.FETCHING_BOOKING_REQUEST,
      });
      const apiResult = identity ? yield fetchBookingRequest(payload.payload) : yield fetchBookingRequestEncrypted(payload.payload);
      const result = apiResult.data;
      if(result.success && result.data.ResponseCode === 'LX001') {
        if(payload.payload.quote_mode === '1') {
          
            const providerCodes = result.data.Quote;
            const splitCodes = providerCodes.split(":");

            let code = [];
            if(splitCodes.length > 0) {
              for(let i = 0; i < splitCodes.length; i++) {
                const splitC = splitCodes[i].split(";");
                code.push(splitC[0]);
              }
            }
          
            const apiResultB = yield fetchProviderCodesRequest(code);
            const resultB = apiResultB.data;

            if(resultB.success) {
              yield put({
                type: actions.FETCHED_BOOKING_REQUEST,
                result: result.data,
                codeWFee: splitCodes,
                courierList: resultB.data,
              });
            } else {
              yield put({
                type: actions.FETCH_BOOKING_REQUEST_FAILED,
              });
            }
        }
        else if(payload.payload.quote_mode === '0') {
          yield put({
            type: actions.FETCHED_BOOKING_REQUEST,
            result: result.data,
          });
        }
      } else {
        yield put({
          type: actions.FETCH_BOOKING_REQUEST_FAILED,
          message: 'Failed on requesting courier provider',
        });
      }
    } catch (error) {
      yield put({
        type: actions.FETCH_BOOKING_REQUEST_FAILED,
        message: 'Failed to request booking.',
      });
    }
  });
}

export function* fetchMerchantStoreSettingsTrx() {
  yield takeEvery('FETCH_MERCHANT_STORE_SETTINGS_TRANX', function* (payload) {
    const state = yield select();
    const identity = state.Auth.identity;
    try {
      yield put({
        type: actions.FETCHING_MERCHANT_STORE_SETTINGS_TRANX,
      });
      const apiResult = identity ? yield getMerchantStoreSettingsTrxRequest(payload.payload) : yield getMerchantStoreSettingsTrxRequestEncrypted(payload.payload);
      const result = apiResult.data;
      if (result.success) {
        yield put({
          type: actions.FETCHED_MERCHANT_STORE_SETTINGS_TRANX,
          data: result.data,
        });
      } else {
        yield put({
          type: actions.FETCH_MERCHANT_STORE_SETTINGS_FAILED_TRANX,
          message: 'Failed to fetch merchant settings.'
        });
      }
    } catch (error) {
      yield put({
        type: actions.FETCH_MERCHANT_STORE_SETTINGS_FAILED_TRANX,
        message: 'Failed to fetch merchant settings.'
      });
    }
  });
}

export function* fetchStoreSettings() {
  yield takeEvery('FETCH_STORE_SETTINGS', function* (payload) {
    const state = yield select();
    const identity = state.Auth.identity;
    try {
      yield put({
        type: actions.FETCHING_STORE_SETTINGS,
      });
      const apiResult = identity ? yield getStoreSettingRequest(payload.payload) : yield getStoreSettingRequestEncrypted(payload.payload);
      const result = apiResult.data;
      if (result.success) {
        yield put({
          type: actions.FETCHED_STORE_SETTINGS,
          data: result.data[0],
        });
      } else {
        yield put({
          type: actions.FETCH_STORE_SETTINGS_FAILED,
          message: 'Failed to fetch store settings.'
        });
      }
    } catch (error) {
      yield put({
        type: actions.FETCH_STORE_SETTINGS_FAILED,
        message: 'Failed to fetch store settings.'
      });
    }
  });
}

export function* fetchCourierOrder() {
  yield takeEvery('FETCH_COURIER_ORDER', function* (payload) {
    try {
      yield put({
        type: actions.FETCHING_COURIER_ORDER,
      });
      const apiResult = yield fetchCourierOrders(payload.payload);
      const result = apiResult.data;
      yield put({
        type: actions.FETCHED_COURIER_ORDER,
        payload: result.data,
      });
    } catch (error) {
      yield put({
        type: actions.FETCH_COURIER_ORDER_FAILED,
      });
    }
  });
}

export function* fetchBookingStatus() {
  yield takeEvery('FETCH_BOOKING_STATUS', function* () {
    try {
      yield put({
        type: actions.FETCHING_BOOKING_STATUS,
      });
      const apiResult = yield fetchBookingState();
      const result = apiResult.data;
      yield put({
        type: actions.FETCHED_BOOKING_STATUS,
        payload: result.data,
      });
    } catch (error) {
      yield put({
        type: actions.FETCH_BOOKING_STATUS_FAILED,
      });
    }
  });
}

export function* fetchTimeline() {
  yield takeEvery('FETCH_SHIPPING_TIMELINE', function* (payload) {
    try {
      yield put({
        type: actions.FETCHING_SHIPPING_TIMELINE,
      });
      const apiResult = yield fetchShippingTimeline(payload);
      const result = apiResult.data;
      yield put({
        type: actions.FETCHED_SHIPPING_TIMELINE,
        payload: result.data,
      });
    } catch (error) {
      yield put({
        type: actions.FETCHING_SHIPPING_TIMELINE_FAILED,
      });
    }
  });
}

export function* reloadTimeline() {
  yield takeEvery('RELOAD_SHIPPING_TIMELINE', function* (payload) {
    try {
      const timeline = yield fetchShippingTimeline(payload);
      if (timeline.status === 200) {
        yield put({
          type: actions.FETCHED_TIMELINE,
          data: timeline.data.data,
        });
      } else {
        yield put({
          type: actions.FETCHING_SHIPPING_TIMELINE_FAILED,
        });
      }
    } catch (error) {
      yield put({
        type: actions.FETCHING_SHIPPING_TIMELINE_FAILED,
      });
    }
  });
}

export function* fetchCustomerDataByIpAddress() {
  yield takeEvery('FETCH_CUSTOMER_DATA_BY_IP_ADDRESS', function* () {
    const state = yield select();
    const identity = state.Auth.identity;
    try {
      yield put({
        type: actions.FETCH_CUSTOMER_DATA_BY_IP_ADDRESS_LOADING,
        payload: true,
      });
      const apiResult = identity ? yield fetchCustomerDataByIpAddressRequest() : yield fetchCustomerDataByIpAddressRequestEncrypted();
      if (apiResult.status === 200) {
        yield put({
          type: actions.FETCH_CUSTOMER_DATA_BY_IP_ADDRESS_SUCCESS,
          payload: apiResult.data,
        });
      } else {
        yield put({
          type: actions.FETCH_CUSTOMER_DATA_BY_IP_ADDRESS_FAILED,
          payload: apiResult,
        });
      }
    } catch (error) {
      yield put({
        type: actions.FETCH_CUSTOMER_DATA_BY_IP_ADDRESS_FAILED,
        payload: error,
      });
    }
  });
}

export function* deleteCustomerDetailsInPhoneBook() {
  yield takeEvery('DELETE_CUSTOMER_DETAILS_IN_PHONE_BOOK', function* ({payload}) {
    try {
      yield put({
        type: actions.DELETE_CUSTOMER_DETAILS_IN_PHONE_BOOK_LOADING,
        payload: true,
      });
      const apiResult = yield deleteCustomerDetailsInPhoneBookRequest(payload);
      if (apiResult.status === 200) {
        yield put({
          type: actions.DELETE_CUSTOMER_DETAILS_IN_PHONE_BOOK_SUCCESS,
          payload: apiResult.data,
        });
      } else {
        yield put({
          type: actions.DELETE_CUSTOMER_DETAILS_IN_PHONE_BOOK_FAILED,
          payload: apiResult,
        });
      }
    } catch (error) {
      yield put({
        type: actions.DELETE_CUSTOMER_DETAILS_IN_PHONE_BOOK_FAILED,
        payload: error,
      });
    }
  });
}

export function* checkIfBirRulingComplied() {
  yield takeEvery('CHECK_IF_BIR_RULING_COMPLIED', function* ({payload}) {
    try {
      yield put({
        type: actions.CHECK_IF_BIR_RULING_COMPLIED_LOADING,
      });
      const apiResult = yield checkIfBirRulingCompliedRequest(payload);
      const result = apiResult.data;
      if (result.success) {
        yield put({
          type: actions.CHECK_IF_BIR_RULING_COMPLIED_SUCCESS,
          payload: result.data,
        });
      } else {
        yield put({
          type: actions.CHECK_IF_BIR_RULING_COMPLIED_FAILED,
          payload: apiResult,
        });
      }
    } catch (error) {
      yield put({
        type: actions.CHECK_IF_BIR_RULING_COMPLIED_FAILED,
        payload: error,
      });
    }
  });
}

export function* fetchSupplierInformation() {
  yield takeEvery('FETCH_SUPPLIER_INFO', function* ({payload}) {
    try {
      yield put({
        type: actions.FETCH_SUPPLIER_INFO_LOADING,
      });
      const apiResult = yield fetchSupplierInfoRequest(payload);
      const result = apiResult.data;
      if (result.success) {
        yield put({
          type: actions.FETCH_SUPPLIER_INFO_SUCCESS,
          payload: result.data,
        });
        yield put({
          type: actions.SET_FILTERED_SUPPLIERS_ARRAY,
          payload: null,
        });
      } else {
        yield put({
          type: actions.FETCH_SUPPLIER_INFO_FAILED,
          payload: result,
        });
      }
    } catch (error) {
      yield put({
        type: actions.FETCH_SUPPLIER_INFO_FAILED,
        payload: error,
      })
    }
  })
}

export function* saveSupplierInfo() {
  yield takeEvery('SAVE_SUPPLIER_INFO', function* ({payload}) {
    try {
      yield put({
        type: actions.SAVE_SUPPLIER_INFO_LOADING,
      });
      const apiResult = yield saveSupplierInfoRequest(payload);
      const result = apiResult.data;
      if (result.success) {
        // yield success is done in mqtt
      } else {
        yield put({
          type: actions.SAVE_SUPPLIER_INFO_FAILED,
          payload: result,
        });
      }
    } catch (error) {
      yield put({
        type: actions.SAVE_SUPPLIER_INFO_FAILED,
        payload: error,
      })
    }
  })
}

export function* updateSupplierInfo() {
  yield takeEvery('UPDATE_SUPPLIER_INFO', function* ({payload, supplierId}) {
    try {
      
      yield put({
        type: actions.UPDATE_SUPPLIER_INFO_LOADING,
      });
      const apiResult = yield updateSupplierInfoRequest(payload, supplierId);
      const result = apiResult.data;
      if (result.success) {
        // yield success is done in mqtt
      } else {
        yield put({
          type: actions.UPDATE_SUPPLIER_INFO_FAILED,
          payload: result,
        });
      }
    } catch (error) {
      yield put({
        type: actions.UPDATE_SUPPLIER_INFO_FAILED,
        payload: error,
      })
    }
  })
}

export function* fetchCwtSettings () {
  yield takeEvery('FETCH_CWT_SETTINGS', function* ({ payload }) {
    try {
      yield put({
        type: actions.FETCH_CWT_SETTINGS_LOADING,
      });
      const apiResult = yield fetchCwtSettingsRequest(payload);
      const result = apiResult.data;
      if (result.success) {
        yield put({
          type: actions.FETCH_CWT_SETTINGS_SUCCESS,
          payload: result.data
        });
      } else {
        yield put({
          type: actions.FETCH_CWT_SETTINGS_FAILED,
          payload: apiResult,
        });
      }
    } catch (error) {
      yield put({
        type: actions.FETCH_CWT_SETTINGS_FAILED,
        payload: error,
        message: "Something went wrong!",
      });
    }
  });
}

export function* updateCwtSettings () {
  yield takeEvery('UPDATE_CWT_SETTINGS', function* ({ payload }) {
    try{
      yield put({
        type: actions.UPDATE_CWT_SETTINGS_LOADING,
      });
      const state = yield select();
      const fetchCwtSettingsSuccess = state.Transactions.fetchCwtSettingsSuccess;
      let apiResult = null;
      if (!fetchCwtSettingsSuccess){
        const createPayload = {
          type: payload.type,
          data: {
            companyName : payload.data.companyName? payload.data.companyName  : "",
            address: payload.data.address? payload.data.address  : "",
            zip: payload.data.zip? payload.data.zip  : "",
            tin: payload.data.tin? payload.data.tin  : "",
            signatureFilename: payload.data.signatureFilename? payload.data.signatureFilename  : "",
            signatureName: payload.data.signatureName? payload.data.signatureName : "",
            dateIssue: payload.data.dateIssue? payload.data.dateIssue  : null,
            dateExpiry: payload.data.dateExpiry? payload.data.dateExpiry  : null,
            merchantId: payload.data.merchantId,
          }
        }
        apiResult = yield createCwtSettingsRequest(createPayload);
      } else {
        apiResult = yield updateCwtSettingsRequest(payload);
      }
      const result = apiResult.data;
      if (result.success) {
        yield put({
          type: actions.UPDATE_CWT_SETTINGS_SUCCESS,
          payload: result.data,
          message: result.response_advise
        });
      } else {
        yield put({
          type: actions.UPDATE_CWT_SETTINGS_FAILED,
          payload: apiResult.data,
          message: `${result.response_message}. Please try again!`,
        });
      }
    } catch(error) {
      yield put({
        type: actions.UPDATE_CWT_SETTINGS_FAILED,
        payload: error,
        message: 'Something went wrong. Please try again!'
      });
    }
  });
}

export function* fetchDisbursementLogs () {
  yield takeEvery('FETCH_DISBURSEMENT_LOGS', function* ({ payload }) {
    try {
      yield put({
        type: actions.DISBURSEMENT_LOGS_LOADING,
      });
      const apiResult = yield fetchDisbursementLogsRequest(payload);
      const result = apiResult.data;
      if(result.success) {
        yield put({
          type: actions.DISBURSEMENT_LOGS,
          payload: result.data,
        });
      } else {
        yield put({
          type: actions.DISBURSEMENT_LOGS_FAILED,
          payload: result.data,
        });
      }
    } catch (error) {
      yield put({
        type: actions.DISBURSEMENT_LOGS_FAILED,
        payload: error
      });
    }
  })
}

export function* fetchCosmosOtpSettings() {
  yield takeEvery('FETCH_COSMOS_OTP_SETTINGS', function* () {
    try {
      yield put({
        type: actions.FETCH_COSMOS_OTP_SETTINGS_LOADING,
      });
      const identity = yield select(state => state.Auth.identity);
      const apiResult = yield fetchCosmosOtpSettingsRequest('COSMOS_OTP', identity);
      const result = apiResult.data;
      if (result.success) {
        yield put({
          type: actions.FETCH_COSMOS_OTP_SETTINGS_SUCCESS,
          payload: result.data
        });
      } else {
        yield put({
          type: actions.FETCH_COSMOS_OTP_SETTINGS_FAILED,
          payload: apiResult,
        });
      }
    } catch (error) {
      yield put({
        type: actions.FETCH_COSMOS_OTP_SETTINGS_FAILED,
        payload: error,
      });
    }
  });
}

export function* requestOtpDisbursements() {
  yield takeEvery('REQUEST_OTP_DISBURSEMENTS', function* ({payload}) {
    try {
      yield put({
        type: actions.REQUEST_OTP_DISBURSEMENTS_LOADING,
      });
      const apiResult = yield requestOtpDisbursementsRequest(payload);
      const result = apiResult.data;
      if (result.success && result.data?.success) {
        yield put({
          type: actions.REQUEST_OTP_DISBURSEMENTS_SUCCESS,
          payload: result.data.data
        });
      } else {
        yield put({
          type: actions.REQUEST_OTP_DISBURSEMENTS_FAILED,
          payload: apiResult,
        });
      }
    } catch (error) {
      yield put({
        type: actions.REQUEST_OTP_DISBURSEMENTS_FAILED,
        payload: error,
      });
    }
  });
}

export function* validateOtpDisbursements() {
  yield takeEvery('VALIDATE_OTP_DISBURSEMENTS', function* ({payload}) {
    try {
      yield put({
        type: actions.VALIDATE_OTP_DISBURSEMENTS_LOADING,
      });
      const apiResult = yield validateOtpDisbursementsRequest(payload);
      const result = apiResult.data;
      if (result.success && result.data?.success) {
        yield put({
          type: actions.VALIDATE_OTP_DISBURSEMENTS_SUCCESS,
          payload: result.data
        });
      } else {
        yield put({
          type: actions.VALIDATE_OTP_DISBURSEMENTS_FAILED,
          payload: apiResult,
        });
      }
    } catch (error) {
      yield put({
        type: actions.VALIDATE_OTP_DISBURSEMENTS_FAILED,
        payload: error,
      });
    }
  });
}

export function* createSupplierDisbursement() {
  yield takeEvery('CREATE_SUPPLIER_DISBURSEMENT', function* ({payload}) {
    try {
      yield put({
        type: actions.CREATE_SUPPLIER_DISBURSEMENT_LOADING,
      });
      const apiResult = yield createSupplierDisbursementRequest(payload);
      const result = apiResult.data;
      if (result.success) {
        // yield success is done in mqtt
      } else {
        yield put({
          type: actions.CREATE_SUPPLIER_DISBURSEMENT_FAILED,
          payload: apiResult,
        });
      }
    } catch (error) {
      yield put({
        type: actions.CREATE_SUPPLIER_DISBURSEMENT_FAILED,
        payload: error,
      });
    }
  });
}

export function* fetchSupplierDisbursements() {
  yield takeEvery('FETCH_SUPPLIER_DISBURSEMENTS', function* ({currentCount, searchWord}) {
    try {
      yield put({
        type: actions.FETCH_SUPPLIER_DISBURSEMENTS_LOADING,
      });
      const auth = yield select(state => state.Auth);
      const identity = auth.identity;
      const role = identity?.role?.role?.label;
      const selectedMerchant = auth.selectedMerchant;
      const merchantId = selectedMerchant !== 'All Merchant' ? selectedMerchant : role === 'Administrator' ? null : identity?.merchantId ? identity.merchantId : null;
      const reqBody = {
        collection: 'supplier_disbursements',
        fieldsToFind: [
          {
            field: 'merchantId',
            value: merchantId
          }
        ],
        fieldsToSearch: [
          {
            fields: [
              'disbursementId',
              'companyName'
            ],
            value: searchWord ? searchWord.trim() : ''
          }
        ],
        fieldsWith: [
          {
            field: 'status',
            values: ['PENDING', 'SUCCESS', 'FAILED']
          }
        ],
        pageSize: 10,
        currentCount,
      }

      const apiResult = yield fetchSupplierDisbursementsRequest(reqBody);
      const result = apiResult.data;
      if (result.success) {
        const supplierDisbursements = yield select(state => state.Transactions.supplierDisbursements);
        yield put({
          type: actions.SET_SUPPLIER_DISBURSEMENTS,
          payload: {
            data: supplierDisbursements?.data ? [...supplierDisbursements.data, ...result.data.data] : result.data.data,
            currentCount: result.data.currentCount,
            totalCount: result.data.totalCount,
            searchWord,
          },
        });
        yield put({
          type: actions.FETCH_SUPPLIER_DISBURSEMENTS_SUCCESS,
          payload: result.data,
        });
      } else {
        yield put({
          type: actions.FETCH_SUPPLIER_DISBURSEMENTS_FAILED,
          payload: apiResult,
        });
      }
    } catch (error) {
      yield put({
        type: actions.FETCH_SUPPLIER_DISBURSEMENTS_FAILED,
        payload: error,
      });
    }
  });
}

export function* updateSupplierDisbursement() {
  yield takeEvery('UPDATE_SUPPLIER_DISBURSEMENT', function* ({payload}) {
    try {
      yield put({
        type: actions.UPDATE_SUPPLIER_DISBURSEMENT_LOADING,
      });
      const apiResult = yield updateSupplierDisbursementRequest(payload);
      const result = apiResult.data;
      if (result.success) {
        // yield success is done in mqtt
      } else {
        yield put({
          type: actions.UPDATE_SUPPLIER_DISBURSEMENT_FAILED,
          payload: apiResult,
        });
      }
    } catch (error) {
      yield put({
        type: actions.UPDATE_SUPPLIER_DISBURSEMENT_FAILED,
        payload: error,
      });
    }
  });
}

function fetchCwtSettingsRequest(mid) {
  return get(`suppliercwtsettings/fetch_by_mid/${mid}`);
}

function updateCwtSettingsRequest(body) {
  return PUT(`suppliercwtsettings/update`, body);
}

function createCwtSettingsRequest(body) {
  return post(`suppliercwtsettings/create`, body);
}

function fetchingCustomFeeCalculator(data) {
  return post(`merchantpchannelfees/fetch/fee_calculator_fees`, data);
}
function validateApp(data) {
  // this.checkToken();
  return post(`registrationbizaccounts/validate_prereg`, data);
}

function fetchStores(mid) {
  // this.checkToken();
  return get(`stores/ui/get_store_by_mid/${mid}`);
}
function getLimit(mid) {
  // this.checkToken();
  return get(`payment_transactions/get_aggregated_report/${mid}`);
}
function fetchAllRoles() {
  // this.checkToken();
  return get(`roles/fetch/store_roles`);
}
function fetchModules() {
  // this.checkToken();
  return get(`system/${systemId}/modules`);
}

function fetchEsTrxRequest(storeId) {
  return get(`elastictransactions/fetch_transaction_by_store_id/${storeId}`);
}
function fetchEsTrxByTypeIdRequest(data, mid) {
  const finalData = {
    mid,
    type: data.type,
    type_id: data.tagId,
  };
  return post(`elastictransactions/fetch_transaction_by_req_and_mid`, finalData);
}
function searchRequest(data) {
  return post(`elastictransactions/new/search_transaction`, data);
}
function fetchMoreRequest(scrollId) {
  return get(`elastictransactions/load_more_transaction/${scrollId}`);
}
function fetchStoreInventory(storeId) {
  return get(`inventories/get_product/ui_new/${storeId}`);
}
function fetchStoreInventoryEncrypted(storeId) {
  return encryptedGet(`inventories/get_product/ui_new_encrypted/${storeId}`);
}
function fetchAllowedChannels(storeId) {
  return get(`storechannels/get_pchannel_by_store_id/${storeId}`);
}
function fetchAllowedChannelsEncrypted(storeId) {
  return encryptedGet(`storechannels/get_pchannel_by_store_id_encrypted/${storeId}`);
}
function fetchProvince() {
  return get(`provinces/fetch/province_data`);
}
function fetchProvinceEncrypted() {
  return encryptedGet(`provinces/fetch/province_data_encrypted`);
}
function fetchProvinceShipping(mid) {
  return get(`provinces/fetch/province_data_shipping/${mid}`);
}
function fetchProvinceShippingEncrypted(mid) {
  return encryptedGet(`provinces/fetch/province_data_shipping_encrypted/${mid}`);
}
function fetchCity(province) {
  return get(`provinces/fetch/city_data/${province}`);
}
function fetchCityEncrypted(province) {
  return encryptedGet(`provinces/fetch/city_data_encrypted/${province}`);
}
function fetchBrgy(province, city) {
  return get(`provinces/fetch/barangay/${province}_AND_${city}`);
}
function fetchBrgyEncrypted(province, city) {
  return encryptedGet(`provinces/fetch/barangay_encrypted/${province}_AND_${city}`);
}
function fetchTempTransaction(requestId) {
  return get(`temptransactions/get_temp_transactions/${requestId}`);
}
function fetchTempTransactionEncrypted(requestId) {
  return encryptedGet(`temptransactions/get_temp_transactions_encrypted/${requestId}`);
}
function checkTrxStatus(requestId) {
  return get(`payment_transactions/check_status/${requestId}`);
}
function checkTrxStatusEncrypted(requestId) {
  return encryptedGet(`payment_transactions/check_status_encrypted/${requestId}`);
}
function fetchingPaymentTransaction(requestId) {
  return get(`payments/fetch_payment_transaction/${requestId}`);
}
function fetchingPaymentTransactionEncrypted(requestId) {
  return encryptedGet(`payments/fetch_payment_transaction_encrypted/${requestId}`);
}
function fetchStoreData(storeId) {
  return get(`stores/get_store/${storeId}`);
}
function fetchStoreDataEncrypted(storeId) {
  return encryptedGet(`stores/get_store_encrypted/${storeId}`);
}
function loadAllTransactionRequest(type, selectedMerchant, view) {
  const data = {
    type: type !== 'All' ? Array.isArray(type) ? type : [type] : ['gr033', 'gr001', 'gr002', 'gr018', 'gr003', 'gr152'],
    merchantId: selectedMerchant ? selectedMerchant : 'All Merchant',
    view
  };
  return post(`elastictransactions/fetch_transaction_by_mid_and_type`, data);
  // return get(`elastictransactions/fetch_transaction_by_merchant_id/${mid}`);
}
function saveFolderRequest(data, trxId) {
  data['transaction_id'] = trxId;
  return post(`transactionfolders/save/folder`, data);
}
function submitTempTransaction(payload) {
  return post(`temptransactions`, payload);
}
function submitKrakenTransaction(payload) {
  return post(`payments/request_to_kraken`, payload);
}
function requestSmsLink(payload) {
  return post(`payments/send_sms_link`, payload);
}
function submitKrakenTransactionCustomer(payload) { // used for redirect customer
  return post(`payments/request_to_kraken_customer`, payload);
}
function saveTransactionCustomer(id, payload, data) { // used for redirect customer
  if (!data) {
    payload.customerChoosePmethod = false;
  } else {
    payload.customerChoosePmethod = true;
  }
  const newPayload = JSON.stringify(payload);
  return PUT(`temptransactions/update/temp_trx/${id}`, newPayload);
}
function saveTransactionCustomerEncrypted(id, payload, data) { // used for redirect customer
  if (!data) {
    payload.customerChoosePmethod = false;
  } else {
    payload.customerChoosePmethod = true;
  }
  const newPayload = JSON.stringify(payload);
  return encryptedPut(`temptransactions/update/temp_trx_encrypted/${id}`, newPayload);
}
function moveFolderRequest(data, trxId) {
  data['transaction_id'] = trxId;
  return post(`transactionfoldercontents/move/folder`, data);
}
function moveTagRequest(data, trxId) {
  data['transaction_id'] = trxId;
  return post(`transactiontagcontents/move/tag`, data);
}
function updateFolderRequest(data) {
  return PUT(`transactionfolders/update/folder/${data.folder_id}`, data);
}
function fetchFoldersRequest(mid) {
  return get(`transactionfolders/fetch/folders/${mid}`);
}
// change the status to deleted
function deleteFolderRequest(id) {
  return PUT(`transactionfolders/delete/folder/${id}`);
}
// change the status to deleted
function deleteTagRequest(id, userId) {
  const data = {
    userId,
  };
  return PUT(`transactiontags/delete/tag/${id}`, data);
}

function updateTagRequest(data) {
  return PUT(`transactiontags/update/tag/${data.tag_id}`, data);
}
function fetchTagsRequest(mid, userId) {
  const data = {
    mid,
    userId,
  };
  return post(`transactiontags/fetch/tags/`, data);
}
function saveTagRequest(data, trxId) {
  data['transaction_id'] = trxId;
  return post(`transactiontags/save/tag`, data);
}
function saveRemarks(user, remarks, requestId) {
  const data = {
    user,
    remarks,
    requestId,
  }
  return post(`elastictransactions/add_remarks`, data);
}
function deleteFolderTxnRequest(transactionId, folderId) {
  return DELETE(`transactionfoldercontents/${folderId}_FLD_${transactionId}`);
}
function removeTxnTagRequest(transaction_id, tag_id) {
  const data = {
    transaction_id,
    tag_id,
  };
  return DELETE(`transactiontagcontents/delete/tag`, data);
}
function createFullyRegistered(data) {
  return post(`registrationbizaccounts/create_fully_registered/`, data);
}
function searchAudit(data) {
  return post(`auditlogs/search/transactions`, data);
}
function searchMerchantAudit(data) {
  return post(`merchants/search/merchant_audit_logs`, data);
}
function loadMoreLogs(scrollId) {
  return get(`auditlogs/load_more_logs/${scrollId}`);
}
function loadMoreMerchantLogs(scrollId) {
  return get(`merchants/load_more_merchant_logs/${scrollId}`);
}
function fetchTrxPdf(payload) {
  return post(`elastictransactions/download_transaction_pdf_v2`, payload);
  // return post(`elastictransactions/download_transaction_pdf`, payload);
}
function fetchPaymentFeesRequest(mid) {
  return get(`merchantsettings/fetch_fee_v2/${mid}`);
}
function fetchMerchantPaymentFeesRequest(mid) {
  const data = {
    merchantId: mid
  }
  return post(`merchantpchannelfees/fetch/pchannel_fees/`, data);
}
function fetchFees(mid) {
  return get(`merchantsettings/fetch_fee_v2/${mid}`);
}
function fetchDetailedTrxDataRequest(reqId) {
  return get(`payment_transactions/fetch_by_request_id/${reqId}`);
}
function fetchImageRequest(img) {
  return get(`merchants/get_merchant_image/${img}`);
}
function fetchImageRequestEncrypted(img) {
  return encryptedGet(`merchants/get_merchant_image_encrypted/${img}`);
}
function fetchingShippingSetup(data) {
  return AuthorizedPost(`shippingsetups/get/shipping_setup/`, data);
}
function fetchRefundValidatorByPchannel(data) {
  return post(`refundvalidators/get_refund_validator_by_pchannel/`, data);
}
function fetchFormTemplate(data) {
  return post(`formbuilders/merchant/fetch`, data)
}
function fetchFormTemplateEncrypted(data) {
  return encryptedPost(`formbuilders/merchant/fetch_encrypted`, data)
}
function fetchItemBodyRequest(inventoryId) {
  const data = {
    inventory_id: inventoryId,
  }
  return post(`inventories/fetch/inventory_body`, data);
}
function fetchItemBodyRequestEncrypted(inventoryId) {
  const data = {
    inventory_id: inventoryId,
  }
  return encryptedPost(`inventories/fetch_encrypted/inventory_body`, data);
}
function fetchBookingRequest(data) {
  return AuthorizedPost(`courier_integ/booking_request`, data);
}
function fetchBookingRequestEncrypted(data) {
  return encryptedPost(`courier_integ/booking_request_encrypted`, data);
}

function fetchProviderCodesRequest(data) {
  const newData = {
    provider_codes: data,
  }
  return AuthorizedPost(`providercodes/fetch/by_codes`, newData);
}
function getMerchantStoreSettingsTrxRequest(id) {
  return get(`merchantsettings/fetch_by_mid/${id}`);
}
function getMerchantStoreSettingsTrxRequestEncrypted(id) {
  return encryptedGet(`merchantsettings/fetch_by_mid_encrypted/${id}`);
}

function getStoreSettingRequest(id) {
  return get(`stores/find_store/${id}`);
}
function getStoreSettingRequestEncrypted(id) {
  return encryptedGet(`stores/find_store_encrypted/${id}`);
}


function fetchCourierOrders(data) {
  return get(`courierordertrackings/fetch_order/${data}`);
}

function fetchBookingState(data) {
  return get(`bookingstatuses/fetch_booking_status`);
}

function fetchShippingTimeline(data) {
  return get(`courierordertrackings/fetch_timeline/${data.payload}`);
}

function fetchCustomerDataByIpAddressRequest() {
  return get(`phonebooks/fetch/by_ip_address`);
}

function fetchCustomerDataByIpAddressRequestEncrypted() {
  return encryptedGet(`phonebooks/fetch/by_ip_address_encrypted`);
}

function deleteCustomerDetailsInPhoneBookRequest(payload) {
  return AuthorizedPut(`phonebooks/${payload}`, { ipAddress: null });
}

function checkIfBirRulingCompliedRequest(mid) {
  return get(`birdocuments/is_bir_ruling_complied/${mid}`);
}

function fetchSupplierInfoRequest(mid) { 
  return get(`supplierinformations/fetch_supplier_info/${mid}`);
}

function saveSupplierInfoRequest(payload) {
  return post(`supplierinformations/`, payload);
}

function updateSupplierInfoRequest(payload, supplierId) {
  return PUT(`supplierinformations/${supplierId}`, payload);
}

function fetchDisbursementLogsRequest(mid) {
  return get(`cwtdisbursementslogs/fetch/disbursements_logs/${mid}`);
}

function fetchCosmosOtpSettingsRequest(key, identity) {
  return identity ? 
    get(`systemsettings/fetch_settings_by_key/${key}`)
    :
    encryptedGet(`systemsettings/fetch_settings_by_key_encrypted/${key}`);
}

function requestOtpDisbursementsRequest(payload) {
  return post(`onetimepasswords/request_otp/create_supplier_disbursement`, payload);
}

function validateOtpDisbursementsRequest(payload) {
  return post(`onetimepasswords/validate_otp/create_supplier_disbursement`, payload);
}

function createSupplierDisbursementRequest(payload) {
  return post(`supplierdisbursements/create`, payload);
}

function fetchSupplierDisbursementsRequest(payload) {
  return post(`supplierdisbursements/find_all/with_pagination`, payload);
}

function updateSupplierDisbursementRequest(payload) {
  return post(`supplierdisbursements/update`, payload);
}

function fetchAuditLogs(payload) {
  return post(`auditlogs/get/auditlogs`, payload);
}

export default function* rootSaga() {
  yield all([
    fork(search),
    fork(saveTag),
    fork(saveFolder),
    fork(moveFolder),
    fork(moveTag),
    fork(updateFolder),
    fork(updateTag),
    fork(fetchFolders),
    fork(fetchTags),
    fork(fetchEsTrxByStoreId),
    fork(fetchEsTrxByTypeId),
    fork(loadAllTransactions),
    fork(loadMoreTransactions),
    fork(deleteFolder),
    fork(deleteTag),
    fork(postRemarks),
    fork(issueRefund),
    fork(mqttIncommingTrx),
    // fork(setView),
    fork(loadStoreAssets),
    fork(loadProvice),
    fork(loadProviceShipping),
    fork(loadCities),
    fork(loadCitiesShipping),
    fork(loadBrgys),
    fork(loadBrgysShipping),
    fork(loadTempTransaction),
    fork(deleteFolderTxn),
    fork(submitTempTrx),
    fork(redirectCustomerSubmitTrx),
    fork(removeTransactionTag),
    fork(submitApplication),
    fork(getAccountThreshold),
    fork(countStoreInventory),
    fork(submitTemporaryTransaction),
    fork(getRedirectTrxStatus),
    fork(searchAuditLogs),
    fork(fetchMoreLogs),
    fork(downloadTrxPdf),
    fork(fetchPaymentFees),
    fork(fetchTxnFees),
    fork(sendSmsLink),
    fork(fetchBocTrxData),
    fork(fetchDetailedTrxData),
    fork(cancelTransaction),
    fork(validateApplication),
    fork(fetchPaymentTransaction),
    fork(fetchShippingSetup),
    fork(getRefundValidator),
    fork(fetchCustomFeeCalculator),
    fork(fetchItemBodyData),
    fork(fetchBookingRequestData),
    fork(fetchMerchantStoreSettingsTrx),
    fork(fetchStoreSettings),
    fork(fetchCourierOrder),
    fork(fetchBookingStatus),
    fork(reloadTimeline),
    fork(fetchTimeline),
    fork(searchMerchantAuditLogs),
    fork(fetchMoreMerchantLogs),
    fork(fetchCustomerDataByIpAddress),
    fork(deleteCustomerDetailsInPhoneBook),
    fork(checkIfBirRulingComplied),
    fork(fetchSupplierInformation),
    fork(saveSupplierInfo),
    fork(updateSupplierInfo),
    fork(fetchCwtSettings),
    fork(updateCwtSettings),
    fork(fetchDisbursementLogs),
    fork(fetchCosmosOtpSettings),
    fork(requestOtpDisbursements),
    fork(validateOtpDisbursements),
    fork(createSupplierDisbursement),
    fork(fetchSupplierDisbursements),
    fork(updateSupplierDisbursement),
    fork(getAuditLogs),
  ]);
}