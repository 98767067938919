import storeActions from '../store/actions';
import actions from './actions';

const initState = {
  idToken: null,
  errorMessage: null,
  signupMessage: null,
  forgotMessage: null,
  modules: null,
  customModules: null,
  roles: null,
  isLoading: false,
  resetSuccess: false,
  identity: null,
  stores: null,
  merchantId: null,
  topic: null,
  secondTimer: 0,
  env: null,
  mqttToken: null,
  forbiddenRequest: false,
  kickedOut: false,
  preRegistered: false,
  customAuthorizations: null,
  showKyc: false,
  // disabledModules: ['CORPORATE_STORE_USER', 'CORPORATE_ADDRESS_BOOK'],
  // disabledSubmodules: ['MERCHANT_BATCH_UPLOAD'],
  disabledModules: [],
  disabledSubmodules: [],
  merchantList: null,
  selectedMerchant: null,
  selectedMerchantName: null,
  fetchingRedirectUrl: false,
  redirectUrl: null,
  redirectRegistrationSuccessData: null,
  redirectSendingEmail: false,
  redirectSendingSuccess: false,
  redirectSendingFailed: false,
  redirectSendingMessage: null,
  fetchingResetPassUrl: false,
  resetPassUrl: null,
  merchantFees: null,
  redirectSendingBatchUploadEmail: false,
  redirectSendingBatchUploadSuccess: false,
  redirectSendingBatchUploadFailed: false,
  redirectSendingBatchUploadMessage: null,
  searchedStoreData: null,
  searchingStoreData: false,
  fetchingStoresV2: false,
  privilege: null,
  otpBanner: false,
  otpEmail: null,
  otpMerchantId: null,
  isSavingOtp: false,
  otpErrorMessage: null,
  otpModal: false,
  otpData: null,
  otpLoading: false,
  user_id: null,
  verifyOtpLoading: false,
  verifyOtpData: null,
  otpAuthToken: null,
  isErrorVerifyOtp: false,
  otpMessage: null,
  isResendingOtp: false,
  verifyOtpMessage: null,
  limitReachMessage: null,
  accountDeletionErrorMessage: null,
  accountDeletionData: null,
  userData: null,
  accountDeletionLoading: false,
  is_exist: false,

  isUpdatePassword: null,
  updatePasswordUsername: null,

  updatePasswordSuccess: null,
  updatePasswordLoading: false,
  updatePasswordFailed: null,

  // REQUEST OTP DISBURSEMENTS
  requestOtpUpdatePasswordSuccess: null,
  requestOtpUpdatePasswordLoading: false,
  requestOtpUpdatePasswordFailed: null,

  // VALIDATE OTP DISBURSEMENTS
  validateOtpUpdatePasswordSuccess: null,
  validateOtpUpdatePasswordLoading: false,
  validateOtpUpdatePasswordFailed: null,

  // RESET PORTAL USER PASSWORD
  resetPortalUserPasswordSuccess: null,
  resetPortalUserPasswordLoading: false,
  resetPortalUserPasswordFailed: null,

  // EXPIRED PASSWORD UPDATE
  expiredPasswordData: null,
  expiredPasswordDataLoading: false,
};

export default function authReducer(state = initState, action) {

  switch (action.type) {
    case actions.LOGIN_SUCCESS:
      const newRegistered = action.modules && action.modules !== undefined && action.modules !== 'undefined' && action.modules !== 'null' ? JSON.parse(action.modules).filter((el) => {
        return el.alias === 'PRE_REG_GET_STARTED';
      }).length > 0 : [];

      const identity = action.identity && action.identity !== undefined && action.identity !== 'undefined' && action.identity !== 'null' ? JSON.parse(action.identity) : null;
      const roles = action.roles && action.roles !== undefined && action.roles !== 'undefined' && action.roles !== 'null' ? JSON.parse(action.roles) : null;
      const merchantFees = action.merchantFees && action.merchantFees !== undefined && action.merchantFees !== 'undefined' && action.merchantFees !== 'null' ? JSON.parse(action.merchantFees) : null;
      const merchantList = action.merchantList && action.merchantList !== undefined && action.merchantList !== 'undefined' && action.merchantList !== 'null' ? JSON.parse(action.merchantList) : [];
      const customModules = action.customModules && action.customModules !== undefined && action.customModules !== 'undefined' && action.customModules !== 'null' ? JSON.parse(action.customModules) : [];
      const customAuthorizations = action.customAuthorizations && action.customAuthorizations !== undefined && action.customAuthorizations !== 'undefined' && action.customAuthorizations !== 'null' ? JSON.parse(action.customAuthorizations) : [];
      const stores = action.stores && action.stores !== undefined && action.stores !== 'undefined' && action.stores !== 'null' ? JSON.parse(action.stores) : [];

      const disabledModules = action.disabledModules && action.disabledModules !== undefined && action.disabledModules !== 'undefined' && action.disabledModules !== 'null' ?
        action.disabledModules.length > 0 ? JSON.parse(action.disabledModules).filter(item => {
          return item.category.toUpperCase() === 'MODULE'
        }) : [] : [];
      const disabledSubmodules = action.disabledModules && action.disabledModules !== undefined && action.disabledModules !== 'undefined' && action.disabledModules !== 'null' ?
        action.disabledModules.length > 0 ? JSON.parse(action.disabledModules).filter(item => {
          return item.category.toUpperCase() === 'SUBMODULE'
        }) : [] : [];
      // sanitize module and remove disabled modules
      const mods = action.modules && action.modules !== undefined && action.modules !== 'undefined' && action.modules !== 'null' ?
        action.modules.length > 0 ? JSON.parse(action.modules).filter((el) => {
          const submods = el.submodules.filter((elB) => {
            // return disabledSubmodules.indexOf(elB.alias) < 0;
            return disabledSubmodules.findIndex(item => item.alias === elB.alias) < 0;
          });
          el.submodules = submods;
          return disabledModules.findIndex(item => item.alias === el.alias) < 0;
          // return disabledModules.indexOf(el.alias) < 0;
        }) : [] : [];

      return {
        ...state,
        idToken: action.token,
        identity: identity,
        modules: mods,
        stores: stores,
        merchantId: action.merchantId,
        customAuthorizations: customAuthorizations,
        customModules: customModules,
        roles: roles,
        errorMessage: null,
        topic: action.topic,
        env: action.env,
        preRegistered: newRegistered,
        merchantList: merchantList,
        selectedMerchant: action.selectedMerchant,
        selectedMerchantName: action.selectedMerchantName,
        disabledModules: disabledModules,
        disabledSubmodules: disabledSubmodules,
        merchantFees: merchantFees,
      };
    case actions.FULLY_REGISTERED:
      // sanitize module and remove disabled modules
      // const mods2 = JSON.parse(action.modules).filter((el) => {
      //   const submods = el.submodules.filter((elB) => {
      //     return state.disabledSubmodules.indexOf(elB.alias) < 0;
      //   });
      //   el.submodules = submods;
      //   return state.disabledModules.indexOf(el.alias) < 0;
      // });
      const mods2 = JSON.parse(action.modules).filter((el) => {
        const submods = el.submodules.filter((elB) => {
          // return disabledSubmodules.indexOf(elB.alias) < 0;
          return state.disabledSubmodules.findIndex(item => item.alias === elB.alias) < 0;
        });
        el.submodules = submods;
        return state.disabledModules.findIndex(item => item.alias === el.alias) < 0;
        // return disabledModules.indexOf(el.alias) < 0;
      });
      return {
        ...state,
        identity: JSON.parse(action.identity),
        modules: mods2,
        stores: JSON.parse(action.stores),
        merchantId: action.merchantId,
        customAuthorizations: JSON.parse(action.customAuthorizations),
        customModules: JSON.parse(action.customModules),
        roles: JSON.parse(action.roles),
        errorMessage: null,
        topic: action.topic,
        preRegistered: false,
      };
    case actions.SET_OTP_BANNER:
      return {
        ...state,
        otpBanner: action.otp_banner,
        otpEmail: action.otp_email,
        otpMerchantId: action.otpMerchantId,
        userId: action.user_id,
      };
    case actions.SAVED_AGREE_OTP:
      return {
        ...state,
        otpBanner: false,
        isSavingOtp: false,
      };
    case actions.SAVING_AGREE_OTP:
      return {
        ...state,
        isSavingOtp: true,
      };
    case actions.OTP_AUTH_TOKEN:
      return {
        ...state,
        otpAuthToken: action.payload,
      };
    case actions.SAVE_AGREE_OTP_FAILED:
      return {
        ...state,
        isSavingOtp: false,
        otpBanner: false,
        otpErrorMessage: 'Error on Saving OTP Config, Please Contact Administrator.'
      };
    case actions.LOGIN_DATA:
      return {
        ...state,
        loginData: action.payload,
      };
    case actions.RESET_ERROR_MESSAGE:
      return {
        ...state,
        otpErrorMessage: null,
      };
    case actions.REQUESTING_LOGIN_OTP:
      return {
        ...state,
        otpLoading: true,
      };
    case actions.REQUESTED_LOGIN_OTP:
      return {
        ...state,
        otpLoading: false,
        otpData: action.otpData,
        otpModal: action.otpModal,
        otpMessage: action.otpMessage,
      };
    case actions.CLEAR_OTP_DATA:
      return {
        ...state,
        otpMessage: null,
        otpModal: false,
        otpData: null,
        isErrorVerifyOtp: false,
        verifyOtpData: null,
        loginData: null,
        otpAuthToken: null,
        limitReachMessage: null,
      };
    case actions.CLEAR_OTP_MESSAGE:
      return {
        ...state,
        otpMessage: null,
        verifyOtpMessage: null,
        limitReachMessage: null,
      };
    case actions.RESENDING_LOGIN_OTP:
      return {
        ...state,
        // verifyOtpLoading: action.payload,
        isErrorVerifyOtp: action.isErrorVerifyOtp,
        isResendingOtp: action.payload,
        verifyOtpMessage: null,
        // otpData: null,
      };
    case actions.SET_SHOW_OTP_MODAL:
      return {
        ...state,
        otpLoading: false,
        otpData: null,
        otpModal: action.payload,
        verifyOtpLoading: false,
        isErrorVerifyOtp: false,
        otpEmail: null,
        isLoading: false,
        kickedOut: false,
        verifyOtpMessage: null,
      };
    case actions.VALIDATING_OTP:
      return {
        ...state,
        verifyOtpLoading: true,
      };
    case actions.VALIDATED_OTP:
      return {
        ...state,
        verifyOtpLoading: false,
        verifyOtpData: action.payload,
        isErrorVerifyOtp: action.isErrorVerifyOtp,
        // ADD THIS LOGIC IF HAS ERROR THE OTP MODAL STILL SHOW UNLESS THE LIMIT OF OTP REACH
        // otpModal: action.isErrorVerifyOtp ? true : false,
        otpModal: action.otpModal,
        verifyOtpMessage: action.verifyOtpMessage,
        limitReachMessage: action.limitReachMessage,
      };
    case actions.LOGIN_ERROR:
      return {
        ...state,
        kickedOut: false,
        errorMessage: action.error,
      };
    case actions.CLEAR_ERROR:
      return {
        ...state,
        errorMessage: null,
        signupMessage: null,
      };
    case actions.SET_LOADING:
      return {
        ...state,
        isLoading: action.loading,
      };
    case actions.SIGNUP:
      return {
        ...state,
        isLoading: action.loading,
      };
    case actions.SIGNUP_ERROR:
      return {
        ...state,
        signupMessage: action.error,
      };
    case actions.FORGOT_ERROR:
      return {
        ...state,
        forgotMessage: action.error,
      };
    case actions.SUBMODULES:
      // sanitize module and remove disabled modules
      const mods3 = action.payload.filter((el) => {
        const submods = el.submodules.filter((elB) => {
          return state.disabledSubmodules.indexOf(elB.alias) < 0;
        });
        el.submodules = submods;
        return state.disabledModules.indexOf(el.alias) < 0;
      });
      return {
        ...state,
        modules: mods3,
      };
    case actions.FORGOT_PASSWORD:
      return {
        ...state,
        email: action.email
      };
    case actions.FORGOT_PASSWORD_DONE:
      return {
        ...state,
        resetSuccess: action.success,
        forgotMessage: null,
      };
    case actions.RESET_SECOND_TIMER:
      return {
        ...state,
        secondTimer: action.secondTimer,
      };
    case actions.KICKED_OUT:
      return {
        ...state,
        kickedOut: true,
      };
    case actions.KICKED_OUT_CLEAR:
      return {
        ...state,
        kickedOut: false,
      };
    case actions.LOGIN_VALIDATOR:
      return {
        ...state,
        mqttToken: action.token,
      };
    case actions.FORBIDDEN_REQUEST:
      return {
        ...state,
        forbiddenRequest: true,
      };
    case actions.LOGOUT:
      return {
        ...initState,
        kickedOut: state.kickedOut,
        errorMessage: state.errorMessage,
      };
    case actions.STORE_UPDATE:
      return {
        ...initState,
        stores: action.payload,
      };
    case actions.TOGGLE_KYC:
      return {
        ...state,
        showKyc: false,
      };
    case actions.SHOW_KYC:
      return {
        ...state,
        showKyc: true,
      };
    case actions.CHANGE_MERCHANT_SUCCESS:
      return {
        ...state,
        selectedMerchant: action.selectedMerchant,
        selectedMerchantName: action.selectedMerchantName,
        stores: action.stores ? JSON.parse(action.stores) : null,
      };
    case actions.FETCH_STORES_SUCCESS:
      return {
        ...state,
        stores: action.stores ? JSON.parse(action.stores) : null,
      };
    //REDIRECT URL
    case actions.FETCH_REDIRECT_URL:
      return initState;
    case actions.FETCHING_REDIRECT_URL:
      return {
        ...state,
        fetchingRedirectUrl: true,
      };
    case actions.FETCHED_REDIRECT_URL:
      return {
        ...state,
        fetchingRedirectUrl: false,
        redirectUrl: action.redirectUrl,
      };
    case actions.FETCH_REDIRECT_URL_FAILED:
      return {
        ...state,
        fetchingRedirectUrl: false,
        redirectUrl: null,
      };
    case storeActions.DELETED_STORE:
      return {
        ...state,
        stores: action.payload ? JSON.parse(action.payload) : null,
      };
    case actions.REDIRECT_REGISTRATION_SUCCESS:
      return {
        ...state,
        redirectRegistrationSuccessData: action.payload ? action.payload : null,
      };
    case actions.SENDING_REGISTRATION_EMAIL:
      return {
        ...state,
        redirectSendingEmail: true,
      };
    case actions.SENT_REGISTRATION_EMAIL:
      return {
        ...state,
        redirectSendingEmail: false,
        redirectSendingSuccess: true,
        redirectSendingMessage: action.payload,
      };
    case actions.RESEND_REGISTRATION_EMAIL_FAILED:
      return {
        ...state, redirectSendingEmail: false,
        redirectSendingFailed: true,
        redirectSendingMessage: action.payload,
      };
    case actions.RESET_RESEND_REGISTRATION_EMAIL:
      return {
        ...state,
        redirectSendingEmail: false,
        redirectSendingSuccess: false,
        redirectSendingFailed: false,
        redirectSendingMessage: null,
      };
    case actions.FETCHING_RESET_PASSWORD_URL:
      return {
        ...state,
        fetchingResetPassUrl: true,
      };
    case actions.FETCHED_RESET_PASSWORD_URL:
      return {
        ...state,
        fetchingResetPassUrl: false,
        resetPassUrl: action.resetPassUrl,
      };
    case actions.FETCH_RESET_PASSWORD_URL_FAILED:
      return {
        ...state,
        fetchingResetPassUrl: false,
        resetPassUrl: null,
      };
    case actions.SENDING_REGISTRATION_BATCH_UPLOAD_EMAIL:
      return {
        ...state,
        redirectSendingBatchUploadEmail: true,
      };
    case actions.SENT_REGISTRATION_BATCH_UPLOAD_EMAIL:
      return {
        ...state,
        redirectSendingBatchUploadEmail: false,
        redirectSendingBatchUploadSuccess: true,
        redirectSendingBatchUploadMessage: action.payload,
      };
    case actions.RESEND_REGISTRATION_EMAIL_BATCH_UPLOAD_FAILED:
      return {
        ...state,
        redirectSendingBatchUploadEmail: false,
        redirectSendingBatchUploadFailed: true,
        redirectSendingBatchUploadMessage: action.payload,
      };
    case actions.RESET_RESEND_REGISTRATION_BATCH_UPLOAD_EMAIL:
      return {
        ...state,
        redirectSendingBatchUploadEmail: false,
        redirectSendingBatchUploadSuccess: false,
        redirectSendingBatchUploadFailed: false,
        redirectSendingBatchUploadMessage: null,
      };

    case actions.FETCHING_STORES_V2:
      return {
        ...state,
        fetchingStoresV2: true,
      };
    case actions.FETCHED_STORES_V2:
      return {
        ...state,
        fetchingStoresV2: false,
        stores: action.stores ? JSON.parse(action.stores) : null,
      };
    case actions.FETCH_STORES_FAILED_V2:
      return {
        ...state,
        fetchingStoresV2: false,
      };

    case actions.SEARCHING_STORE_DATA:
      return {
        ...state,
        searchingStoreData: true,
      };
    case actions.SEARCHED_STORE_DATA:
      return {
        ...state,
        searchingStoreData: false,
        // searchedStoreData: null,
        stores: action.stores ? JSON.parse(action.stores) : null,
      };
    case actions.SEARCH_STORE_DATA_FAILED:
      return {
        ...state,
        searchingStoreData: false,
      };
    case actions.SET_PRIVILEGE:
      return {
        ...state,
        privilege: action.payload,
      };
    case actions.FETCHED_USER_DATA:
      return {
        ...state,
        userData: action.payload,
        accountDeletionLoading: false,
        is_exist: action.is_exist,
        accountDeletionErrorMessage: action.message,
      };
    case actions.FETCHING_USER_DATA:
      return {
        ...state,
        accountDeletionLoading: true,
      };
    case actions.FETCH_USER_DATA_FAILED:
      return {
        ...state,
        accountDeletionLoading: false,
        accountDeletionErrorMessage: action.message,
        is_exist: action.is_exist,
      };
    case actions.CLEAR_ACCOUNT_DELETION_DATA:
      return {
        ...state,
        accountDeletionLoading: false,
        accountDeletionErrorMessage: null,
      };
    case actions.REQUESTED_ACCOUNT_DELETION:
      return {
        ...state,
        accountDeletionData: action.payload,
        accountDeletionLoading: false,
      };
    case actions.REQUESTING_ACCOUNT_DELETION:
      return {
        ...state,
        accountDeletionLoading: true,
      };
    case actions.REQUEST_ACCOUNT_DELETION_FAILED:
      return {
        ...state,
        accountDeletionLoading: false,
        accountDeletionErrorMessage: action.message,
      };
    // Enrolle Bank Account Sub-module
    case actions.UPDATE_OTP_REMOVE_BANK_SUCCESS:
      return {
        ...state,
        identity: action.newIdentity,
      };
    case actions.SET_IS_UPDATE_PASSWORD:
      return {
        ...state,
        isUpdatePassword: action.payload.isUpdatePassword,
        updatePasswordUsername: action.payload.username,
      }
    case actions.UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,
        updatePasswordSuccess: action.payload,
        updatePasswordLoading: false,
        updatePasswordFailed: null,
      }
    case actions.UPDATE_PASSWORD_LOADING:
      return {
        ...state,
        updatePasswordSuccess: null,
        updatePasswordLoading: true,
        updatePasswordFailed: null,
      }
    case actions.UPDATE_PASSWORD_FAILED:
      return {
        ...state,
        updatePasswordSuccess: null,
        updatePasswordLoading: false,
        updatePasswordFailed: action.payload,
      }
    case actions.RESET_UPDATE_PASSWORD:
      return {
        ...state,
        updatePasswordSuccess: null,
        updatePasswordLoading: false,
        updatePasswordFailed: null,
      }
    case actions.REQUEST_OTP_UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,
        requestOtpUpdatePasswordSuccess: action.payload,
        requestOtpUpdatePasswordLoading: false,
        requestOtpUpdatePasswordFailed: null,
      };
    case actions.REQUEST_OTP_UPDATE_PASSWORD_LOADING:
      return {
        ...state,
        requestOtpUpdatePasswordLoading: true,
      };
    case actions.REQUEST_OTP_UPDATE_PASSWORD_FAILED:
      return {
        ...state,
        requestOtpUpdatePasswordSuccess: null,
        requestOtpUpdatePasswordLoading: false,
        requestOtpUpdatePasswordFailed: action.payload,
      };
    case actions.REQUEST_OTP_UPDATE_PASSWORD_RESET:
      return {
        ...state,
        requestOtpUpdatePasswordSuccess: null,
        requestOtpUpdatePasswordLoading: false,
        requestOtpUpdatePasswordFailed: null,
      };
    case actions.VALIDATE_OTP_UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,
        validateOtpUpdatePasswordSuccess: action.payload,
        validateOtpUpdatePasswordLoading: false,
        validateOtpUpdatePasswordFailed: null,
      };
    case actions.VALIDATE_OTP_UPDATE_PASSWORD_LOADING:
      return {
        ...state,
        validateOtpUpdatePasswordLoading: true,
      };
    case actions.VALIDATE_OTP_UPDATE_PASSWORD_FAILED:
      return {
        ...state,
        validateOtpUpdatePasswordSuccess: null,
        validateOtpUpdatePasswordLoading: false,
        validateOtpUpdatePasswordFailed: action.payload,
      };
    case actions.VALIDATE_OTP_UPDATE_PASSWORD_RESET:
      return {
        ...state,
        validateOtpUpdatePasswordSuccess: null,
        validateOtpUpdatePasswordLoading: false,
        validateOtpUpdatePasswordFailed: null,
      };
    case actions.RESET_PORTAL_USER_PASSWORD_SUCCESS:
      return {
        ...state,
        resetPortalUserPasswordSuccess: action.payload,
        resetPortalUserPasswordLoading: false,
        resetPortalUserPasswordFailed: null,
      };
    case actions.RESET_PORTAL_USER_PASSWORD_LOADING:
      return {
        ...state,
        resetPortalUserPasswordSuccess: null,
        resetPortalUserPasswordLoading: true,
        resetPortalUserPasswordFailed: null,
      };
    case actions.RESET_PORTAL_USER_PASSWORD_FAILED:
      return {
        ...state,
        resetPortalUserPasswordSuccess: null,
        resetPortalUserPasswordLoading: false,
        resetPortalUserPasswordFailed: action.payload,
      };
    case actions.CLEAR_RESET_PASSWORD_DATA:
      return {
        ...state,
        resetPortalUserPasswordSuccess: null,
        resetPortalUserPasswordLoading: false,
        resetPortalUserPasswordFailed: null,
      };
    case actions.EXPIRED_PASSWORD_SUCCESS:
      return {
        ...state,
        expiredPasswordData: action.payload,
        expiredPasswordDataLoading: false,
      }
    case actions.EXPIRED_PASSWORD_LOADING:
      return {
        ...state,
        expiredPasswordData: null,
        expiredPasswordDataLoading: true,
      }
    case actions.EXPIRED_PASSWORD_FAILED:
      return {
        ...state,
        expiredPasswordDataLoading: false,
        expiredPasswordData: action.payload,
      }
    case actions.RESET_EXPIRED_PASSWORD:
      return {
        ...state,
        expiredPasswordData: null,
        expiredPasswordDataLoading: false,
      }
    default:
      return state;
  }
}
