const actions = {
  CHECK_AUTHORIZATION: 'CHECK_AUTHORIZATION',
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGOUT: 'LOGOUT',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERROR: 'LOGIN_ERROR',
  FORGOT_ERROR: 'FORGOT_ERROR',
  CLEAR_ERROR: 'CLEAR_ERROR',
  SUBMODULES: 'SUBMODULES',
  SET_LOADING: 'SET_LOADING',
  FORGOT_PASSWORD: 'FORGOT_PASSWORD',
  FORGOT_PASSWORD_DONE: 'FORGOT_PASSWORD_DONE',
  SIGNUP: 'SIGNUP',
  SIGNUP_ERROR: 'SIGNUP_ERROR',
  RESET_SECOND_TIMER: 'RESET_SECOND_TIMER',
  ON_RESET_TIMER: 'ON_RESET_TIMER',
  LOGIN_VALIDATOR: 'LOGIN_VALIDATOR',
  FORBIDDEN_REQUEST: 'FORBIDDEN_REQUEST',
  KICKED_OUT: 'KICKED_OUT',
  KICKED_OUT_CLEAR: 'KICKED_OUT_CLEAR',
  STORE_UPDATE: 'STORE_UPDATE',
  TOGGLE_KYC: 'TOGGLE_KYC',
  SHOW_KYC: 'SHOW_KYC',
  FULLY_REGISTERED: 'FULLY_REGISTERED',
  CHANGE_MERCHANT: 'CHANGE_MERCHANT',
  CHANGE_MERCHANT_SUCCESS: 'CHANGE_MERCHANT_SUCCESS',
  FETCH_STORES: 'FETCH_STORES',
  FETCH_STORES_SUCCESS: 'FETCH_STORES_SUCCESS',
  // GET REDIRECT URL
  FETCH_REDIRECT_URL: 'FETCH_REDIRECT_URL',
  FETCHING_REDIRECT_URL: 'FETCHING_REDIRECT_URL',
  FETCHED_REDIRECT_URL: 'FETCHED_REDIRECT_URL',
  FETCH_REDIRECT_URL_FAILED: 'FETCH_REDIRECT_URL_FAILED',
  // REGISTRATION SUCCESS MQTT DATA
  REDIRECT_REGISTRATION_SUCCESS: 'REDIRECT_REGISTRATION_SUCCESS',
  // RESEND REGISTRATION EMAIL
  RESEND_REGISTRATION_EMAIL: 'RESEND_REGISTRATION_EMAIL',
  SENDING_REGISTRATION_EMAIL: 'SENDING_REGISTRATION_EMAIL',
  SENT_REGISTRATION_EMAIL: 'SENT_REGISTRATION_EMAIL',
  RESEND_REGISTRATION_EMAIL_FAILED: 'RESEND_REGISTRATION_EMAIL_FAILED',
  RESET_RESEND_REGISTRATION_EMAIL: 'RESET_RESEND_REGISTRATION_EMAIL',
  // RESEND REGISTRATION BATCH UPLOAD EMAIL
  RESEND_REGISTRATION_BATCH_UPLOAD_EMAIL: 'RESEND_REGISTRATION_BATCH_UPLOAD_EMAIL',
  SENDING_REGISTRATION_BATCH_UPLOAD_EMAIL: 'SENDING_REGISTRATION_BATCH_UPLOAD_EMAIL',
  SENT_REGISTRATION_BATCH_UPLOAD_EMAIL: 'SENT_REGISTRATION_BATCH_UPLOAD_EMAIL',
  RESEND_REGISTRATION_EMAIL_BATCH_UPLOAD_FAILED: 'RESEND_REGISTRATION_EMAIL_BATCH_UPLOAD_FAILED',
  RESET_RESEND_REGISTRATION_BATCH_UPLOAD_EMAIL: 'RESET_RESEND_REGISTRATION_BATCH_UPLOAD_EMAIL',
  // GET RESET PASSWORD URL
  FETCH_RESET_PASSWORD_URL: 'FETCH_RESET_PASSWORD_URL',
  FETCHING_RESET_PASSWORD_URL: 'FETCHING_RESET_PASSWORD_URL',
  FETCHED_RESET_PASSWORD_URL: 'FETCHED_RESET_PASSWORD_URL',
  FETCH_RESET_PASSWORD_URL_FAILED: 'FETCH_RESET_PASSWORD_URL_FAILED',
  // SEARCH STORE FUNCTION
  FETCH_STORES_V2: 'FETCH_STORES_V2',
  FETCHING_STORES_V2: 'FETCHING_STORES_V2',
  FETCHED_STORES_V2: 'FETCHED_STORES_V2',
  FETCH_STORES_FAILED_V2: 'FETCH_STORES_FAILED_V2',
  
  SEARCH_STORE_DATA: 'SEARCH_STORE_DATA',
  SEARCHING_STORE_DATA: 'SEARCHING_STORE_DATA',
  SEARCHED_STORE_DATA: 'SEARCHED_STORE_DATA',
  SEARCH_STORE_DATA_FAILED: 'SEARCH_STORE_DATA_FAILED',
  SET_PRIVILEGE: 'SET_PRIVILEGE',

  SET_OTP_BANNER: 'SET_OTP_BANNER',
  SAVE_AGREE_OTP: 'SAVE_AGREE_OTP',
  SAVING_AGREE_OTP: 'SAVING_AGREE_OTP',
  SAVED_AGREE_OTP: 'SAVED_AGREE_OTP',
  SAVE_AGREE_OTP_FAILED: 'SAVE_AGREE_OTP_FAILED',

  REQUEST_LOGIN_OTP: 'REQUEST_LOGIN_OTP',
  REQUESTING_LOGIN_OTP: 'REQUESTING_LOGIN_OTP',
  REQUESTED_LOGIN_OTP: 'REQUESTED_LOGIN_OTP',
  ERROR_REQUEST_LOGIN_OTP: 'ERROR_REQUEST_LOGIN_OTP',
  LOGIN_DATA: 'LOGIN_DATA',
  SET_SHOW_OTP_MODAL: 'SET_SHOW_OTP_MODAL',

  VALIDATE_OTP: 'VALIDATE_OTP',
  VALIDATING_OTP: 'VALIDATING_OTP',
  VALIDATED_OTP: 'VALIDATED_OTP',
  VALIDATE_OTP_FAILED: 'VALIDATE_OTP_FAILED',
  OTP_AUTH_TOKEN: 'OTP_AUTH_TOKEN',
  CLEAR_OTP_MESSAGE: 'CLEAR_OTP_MESSAGE',
  CLEAR_OTP_DATA: 'CLEAR_OTP_DATA',

  RESEND_LOGIN_OTP: 'RESEND_LOGIN_OTP',
  RESENDING_LOGIN_OTP: 'RESENDING_LOGIN_OTP',
  RESEND_LOGIN_OTP_FAILED: 'RESEND_LOGIN_OTP_FAILED',

  REQUEST_DEACTIVATE_OTP: 'REQUEST_DEACTIVATE_OTP',

  FETCH_USER_DATA: 'FETCH_USER_DATA',
  FETCHING_USER_DATA: 'FETCHING_USER_DATA',
  FETCHED_USER_DATA: 'FETCHED_USER_DATA',
  FETCH_USER_DATA_FAILED: 'FETCH_USER_DATA_FAILED',
  CLEAR_ACCOUNT_DELETION_DATA: 'CLEAR_ACCOUNT_DELETION_DATA',
  REQUEST_ACCOUNT_DELETION: 'REQUEST_ACCOUNT_DELETION',
  REQUESTING_ACCOUNT_DELETION: 'REQUESTING_ACCOUNT_DELETION',
  REQUESTED_ACCOUNT_DELETION: 'REQUESTED_ACCOUNT_DELETION',
  REQUEST_ACCOUNT_DELETION_FAILED: 'REQUEST_ACCOUNT_DELETION_FAILED',
  RESET_ERROR_MESSAGE: 'RESET_ERROR_MESSAGE',

  // Enrolled Bank Account Submodule 
  UPDATE_IDENTITY_OTP_REMOVE_BANK: 'UPDATE_IDENTITY_OTP_REMOVE_BANK',
  UPDATE_OTP_REMOVE_BANK_SUCCESS: 'UPDATE_OTP_REMOVE_BANK_SUCCESS',

  //Update password
  SET_IS_UPDATE_PASSWORD: 'SET_IS_UPDATE_PASSWORD',
  UPDATE_PASSWORD: 'UPDATE_PASSWORD',
  UPDATE_PASSWORD_SUCCESS: 'UPDATE_PASSWORD_SUCCESS',
  UPDATE_PASSWORD_LOADING: 'UPDATE_PASSWORD_LOADING',
  UPDATE_PASSWORD_FAILED: 'UPDATE_PASSWORD_FAILED',
  RESET_UPDATE_PASSWORD: 'RESET_UPDATE_PASSWORD',

  // REQUEST OTP FOR UPDATING PASSWORD
  REQUEST_OTP_UPDATE_PASSWORD: 'REQUEST_OTP_UPDATE_PASSWORD',
  REQUEST_OTP_UPDATE_PASSWORD_SUCCESS: 'REQUEST_OTP_UPDATE_PASSWORD_SUCCESS',
  REQUEST_OTP_UPDATE_PASSWORD_LOADING: 'REQUEST_OTP_UPDATE_PASSWORD_LOADING',
  REQUEST_OTP_UPDATE_PASSWORD_FAILED: 'REQUEST_OTP_UPDATE_PASSWORD_FAILED',
  REQUEST_OTP_UPDATE_PASSWORD_RESET: 'REQUEST_OTP_UPDATE_PASSWORD_RESET',

  // VALIDATE OTP FOR UPDATING PASSWORD
  VALIDATE_OTP_UPDATE_PASSWORD: 'VALIDATE_OTP_UPDATE_PASSWORD',
  VALIDATE_OTP_UPDATE_PASSWORD_SUCCESS: 'VALIDATE_OTP_UPDATE_PASSWORD_SUCCESS',
  VALIDATE_OTP_UPDATE_PASSWORD_LOADING: 'VALIDATE_OTP_UPDATE_PASSWORD_LOADING',
  VALIDATE_OTP_UPDATE_PASSWORD_FAILED: 'VALIDATE_OTP_UPDATE_PASSWORD_FAILED',
  VALIDATE_OTP_UPDATE_PASSWORD_RESET: 'VALIDATE_OTP_UPDATE_PASSWORD_RESET',

  // RESET PASSWORD
  RESET_PORTAL_USER_PASSWORD: 'RESET_PORTAL_USER_PASSWORD',
  RESET_PORTAL_USER_PASSWORD_SUCCESS: 'RESET_PORTAL_USER_PASSWORD_SUCCESS',
  RESET_PORTAL_USER_PASSWORD_LOADING: 'RESET_PORTAL_USER_PASSWORD_LOADING',
  RESET_PORTAL_USER_PASSWORD_FAILED: 'RESET_PORTAL_USER_PASSWORD_FAILED',
  CLEAR_RESET_PASSWORD_DATA: 'CLEAR_RESET_PASSWORD_DATA',

  EXPIRED_PASSWORD: 'EXPIRED_PASSWORD',
  EXPIRED_PASSWORD_SUCCESS: 'EXPIRED_PASSWORD_SUCCESS',
  EXPIRED_PASSWORD_LOADING: 'EXPIRED_PASSWORD_LOADING',
  EXPIRED_PASSWORD_FAILED: 'EXPIRED_PASSWORD_FAILED',
  RESET_EXPIRED_PASSWORD: 'RESET_EXPIRED_PASSWORD',

  searchStore: (payload) => ({
    type: actions.SEARCH_STORE_DATA,
    payload,
  }),
  setPrivilege: (payload) => ({
    type: actions.SET_PRIVILEGE,
    payload,
  }),
  fetchStoreV2: () => ({
    type: actions.FETCH_STORES_V2
  }),
  checkAuthorization: () => ({ type: actions.CHECK_AUTHORIZATION }),
  login: (payload) => ({
    type: actions.LOGIN_REQUEST,
    payload: payload,
  }),
  signup: (payload) => ({
    type: actions.SIGNUP,
    loading: true,
    payload: payload,
  }),
  logout: () => ({
    type: actions.LOGOUT,
  }),
  clearError: () => ({
    type: actions.CLEAR_ERROR,
  }),
  setSubmodules: (payload) => ({
    type: actions.SUBMODULES,
    modules: payload,
  }),
  forgotPassword: (email) => ({
    type: actions.FORGOT_PASSWORD,
    email,
  }),
  resetSecondTimer: (payload) => ({
    type: actions.RESET_SECOND_TIMER,
    secondTimer: payload,
  }),
  unKick: () => ({
    type: actions.KICKED_OUT_CLEAR,
  }),
  toggleKyc: () => ({
    type: actions.TOGGLE_KYC,
  }),
  showKyc: () => ({
    type: actions.SHOW_KYC,
  }),
  changeMerchant: (payload) => ({
    type: actions.CHANGE_MERCHANT,
    payload,
  }),
  fetchStore: () => ({
    type: actions.FETCH_STORES
  }),
  fetchRedirectUrl: () => ({
    type: actions.FETCH_REDIRECT_URL,
  }),
  resendRegistrationEmail: (payload) => ({
    type: actions.RESEND_REGISTRATION_EMAIL,
    payload,
  }),
  resetResendRegistrationEmail: () => ({
    type: actions.RESET_RESEND_REGISTRATION_EMAIL,
  }),
  resendRegistrationBatchUploadEmail: (payload) => ({
    type: actions.RESEND_REGISTRATION_BATCH_UPLOAD_EMAIL,
    payload,
  }),
  resetResendRegistrationBatchUploadEmail: () => ({
    type: actions.RESET_RESEND_REGISTRATION_BATCH_UPLOAD_EMAIL,
  }),
  fetchResetPassUrl: () => ({
    type: actions.FETCH_RESET_PASSWORD_URL,
  }),
  setIsAgreeOtp: (payload) => ({
    type: actions.SAVE_AGREE_OTP,
    payload
  }),
  resetErrorMessage: () => ({
    type: actions.RESET_ERROR_MESSAGE
  }),
  generateLoginOtp: (payload) => ({
    type: actions.REQUEST_LOGIN_OTP,
    payload: payload,
  }),
  generateDeactivateOtp: (payload) => ({
    type: actions.REQUEST_DEACTIVATE_OTP,
    payload: payload,
  }),
  loginData: (payload) => ({
    type: actions.LOGIN_DATA,
    payload: payload,
  }),
  setShowOtpModal: (payload) => ({
    type: actions.SET_SHOW_OTP_MODAL,
    payload: payload,
  }),
  validateOtp: (payload) => ({
    type: actions.VALIDATE_OTP,
    payload: payload,
  }),
  clearOtpMessage: () => ({
    type: actions.CLEAR_OTP_MESSAGE,
  }),
  resendOtp: (payload) => ({
    type: actions.RESEND_LOGIN_OTP,
    payload,
  }),
  otpAttemps: (payload) => ({
    type: actions.RESEND_LOGIN_OTP,
    payload,
  }),
  clearOtpData: (payload) => ({
    type: actions.CLEAR_OTP_DATA,
    payload,
  }),
  fetchUserData: (payload) => ({
    type: actions.FETCH_USER_DATA,
    payload,
  }),
  clearAccountDeletion: () => ({
    type: actions.CLEAR_ACCOUNT_DELETION_DATA,
  }),
  requestAccountDeletion: (payload) => ({
    type: actions.REQUEST_ACCOUNT_DELETION,
    payload,
  }),
  loginError: (payload) => ({
    type: actions.LOGIN_ERROR,
    error: payload,
  }),


  // Enrolled Bank Account Sub-module
  updateIdentityOtpRemoveBank: () => ({
    type: actions.UPDATE_IDENTITY_OTP_REMOVE_BANK,
  }),

   setIsUpdatePassword: (payload) => ({
    type: actions.SET_IS_UPDATE_PASSWORD,
    payload,
  }),
  updatePassword: (payload) => ({
    type: actions.UPDATE_PASSWORD,
    payload,
  }),
  resetUpdatePassword: () => ({
    type: actions.RESET_UPDATE_PASSWORD,
  }),
  requestOtpUpdatePassword: (payload) => ({
    type: actions.REQUEST_OTP_UPDATE_PASSWORD,
    payload
  }),
  requestOtpUpdatePasswordReset: () => ({
    type: actions.REQUEST_OTP_UPDATE_PASSWORD_RESET,
  }),
  validateOtpUpdatePassword: (payload) => ({
    type: actions.VALIDATE_OTP_UPDATE_PASSWORD,
    payload
  }),
  validateOtpUpdatePasswordReset: () => ({
    type: actions.VALIDATE_OTP_UPDATE_PASSWORD_RESET,
  }),
  resetPortalUserPassword: (payload) => ({
    type: actions.RESET_PORTAL_USER_PASSWORD,
    payload,
  }),
  clearResetPasswordData: () => ({
    type: actions.CLEAR_RESET_PASSWORD_DATA,
  }),
  expiredPasswordUpdate: (payload) => ({
    type: actions.EXPIRED_PASSWORD,
    payload,
  }),
  clearExpiredPassword: () => ({
    type: actions.RESET_EXPIRED_PASSWORD
  }),
};
export default actions;
