import axios from 'axios';
import { verify } from 'jsonwebtoken';

const get = (uri) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const timeout = 60000
  const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  }

  const result = axios.request({
    url: `${apiUrl}${uri}`,
    timeout,
    headers,
  }).then((res) => {
    const { payload } = verify(res.data, `${process.env.REACT_APP_ENV_KEY}`);
    return {...res, data: payload};
  }).catch((error) => {
    return error
  })

  return result;
}

export default async function netcore(params) {
  if (window.smartech) {
    const apiResult = await get('systemsettings/fetch/netcore_settings')
    if (apiResult?.status === 200 && apiResult?.data?.success && apiResult?.data?.data?.enabled === 'true') {
      const netcoreSystemSettings = apiResult.data.data

      window.smartech('create', netcoreSystemSettings.panel_identifier);
      window.smartech('register', netcoreSystemSettings.website_identifier);
      window.smartech('identify', params?.email ? params.email : '');

      // UPLOAD ACTIVITY
      if (params?.type === 'activity') {
        const activityArray = [
          'screen_load_event',
          'sign_up',
          'sign_in',
          'business_ac_success',
          'kyc_generated',
          'merchant_profile_completed',
          'transaction_created_merchant',
          'webstore_created',
          'inventory_added',
        ];
        const isActivityNameExist = activityArray.find(activity => activity === params.activityName)
        if (isActivityNameExist) {
          window.smartech('dispatch', params.activityName,  params?.activityData ? params.activityData : {})
        }
      }

      // ADD OR UPDATE CONTACT
      if (params?.type === 'contact' && params?.email) {
        window.smartech('contact', params.listid ? params.listid.toString() : 'LIST IDENTIFIER', {
          'pk^email': params.email,
          ...params.contactData
        });
      }
    }
  }
};
